"use client";

import { usePathname } from "next-intl/client";
import { Button } from "../../ui/button";
import type { ModelsCategory } from "../../../types/strapi/models";
import type { Theme } from "../../../types/strapi/common";

interface BlogListCategoryLinkProps {
  theme: Theme;
  category: ModelsCategory;
  sectionName: string;
}

function BlogListCategoryLink({
  theme,
  category,
  sectionName,
}: BlogListCategoryLinkProps) {
  const currentPath = usePathname();
  const { name, path } = category;

  const active = currentPath === path;
  const colorByTheme = theme === "dark" ? "gray" : "grayDark";

  return (
    <Button
      appearance="solid"
      color={active ? "blueDark" : colorByTheme}
      href={path}
      sectionName={`${sectionName}_${name}`}
      size="xxs"
      title={name}
    >
      {name}
    </Button>
  );
}

export default BlogListCategoryLink;
