import NextImage from "next/image";
import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import { useQuoteCard, useQuoteCardProps } from "./use-quote-card";

export type QuoteCardProps = useQuoteCardProps;

const QuoteCard = (props: QuoteCardProps) => {
  const {
    className,
    styles,
    data: { name, role, description, avatar, logo },
  } = useQuoteCard(props);

  return (
    <article
      data-component-name="quote-card"
      className={styles.base({ className })}
    >
      {avatar && (
        <NextImage
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          src={avatar.url}
          alt={avatar.alternativeText || ""}
          className={styles.bgImage()}
        />
      )}
      <div className={styles.wrapper()}>
        <div className={styles.body()}>
          {!avatar && (
            <Vector
              className={styles.quoteBefore()}
              name="quote-before-filled"
            />
          )}
          <Text size="h6" className={styles.description()}>
            {description}
          </Text>
          {!avatar && (
            <Vector className={styles.quoteAfter()} name="quote-after-filled" />
          )}
        </div>
        <footer className={styles.footer()}>
          <div className={styles.footerDetails()}>
            <Text tag="cite" className={styles.name()} size="description">
              {name}
            </Text>
            {!!role && (
              <Text tag="span" size="tag" className={styles.role()}>
                {role}
              </Text>
            )}
          </div>
          {logo && (
            <NextImage
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              width={logo.width}
              height={45}
              src={logo.url}
              alt={logo.alternativeText || ""}
              className={styles.logo()}
            />
          )}
        </footer>
      </div>
    </article>
  );
};

export default QuoteCard;
