"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import type { SectionsContentGallery } from "../../../types";
import { GalleryCard } from "../../common/gallery-card";

export interface ContentGallerySwiperProps {
  className?: string;
  gallery: SectionsContentGallery["gallery"];
}

function ContentGallerySwiper({
  className = "",
  gallery,
}: ContentGallerySwiperProps) {
  return (
    <Swiper
      autoplay={{ delay: 2500 }}
      breakpoints={{
        0: {
          slidesPerView: 1.1,
        },
        480: {
          slidesPerView: 2.1,
        },
        768: {
          slidesPerView: 1.1,
        },
      }}
      className={className}
      effect="slide"
      loop
      modules={[Autoplay]}
      simulateTouch={false}
      slidesPerView={1.1}
      spaceBetween={20}
      speed={1000}
    >
      {gallery.map((image) => (
        <SwiperSlide key={`content-gallery-${image.id}`}>
          <GalleryCard image={image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default ContentGallerySwiper;
