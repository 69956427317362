import { type RefObject, useMemo } from "react";
import WaveSurfer, { type WaveSurferOptions } from "wavesurfer.js";
import { ShowcaseContainerRef } from "./showcase-context";
import { useWavesurferState } from "./use-wavesurfer-state";
import { useWavesurferInstance } from "./use-wavesurfer-instance";

interface PlayerPlayEvent extends CustomEvent {
  detail: {
    wavesurfer: WaveSurfer[];
  };
}

declare global {
  interface WindowEventMap {
    "mai-player::play": PlayerPlayEvent;
  }
}

export type usePlayerProps = Omit<WaveSurferOptions, "container" | "url"> & {
  containerRefs: RefObject<ShowcaseContainerRef>;
  tracks: Record<string, string>;
};

export const useWavesurfer = (
  props: usePlayerProps
): ReturnType<typeof useWavesurferState> & {
  wavesurfer: ReturnType<typeof useWavesurferInstance>;
} => {
  const { containerRefs, tracks, ...options } = props;

  const wavesurfer = useWavesurferInstance(containerRefs, tracks, options);
  const state = useWavesurferState(wavesurfer);

  return useMemo(
    () => ({
      ...state,
      wavesurfer,
    }),
    [state, wavesurfer]
  );
};
