import { tv, type VariantProps } from "../../../util/tv";

export const mediaCardVariant = tv({
  slots: {
    base: ["group/item", "flex flex-col", "h-full", "rounded-3xl"],
    figureWrapper: ["w-full"],
    figure: [
      "aspect-h-9 aspect-w-16",
      "rounded-3xl",
      "overflow-hidden",
      "focus-within:outline focus-within:outline-2",
    ],
    image: ["object-cover object-center"],
    caption: ["flex flex-col gap-y-6"],
    heading: [],
    preTitle: ["mb-2"],
    title: ["trasition-colors duration-300"],
    description: ["mt-3"],
    footing: ["flex items-center gap-5", "w-full"],
    name: [],
    date: [],
  },
  variants: {
    theme: {
      dark: {
        base: ["text-white"],
        figure: ["focus-within:outline-blue-400/50"],
        preTitle: ["text-blue-400"],
        title: ["group-hover/item:text-blue-400"],
        description: ["text-gray-40"],
      },
      light: {
        base: ["text-black"],
        figure: ["focus-within:outline-blue-100/50"],
        preTitle: ["text-blue-100"],
        title: ["group-hover/item:text-blue-100"],
        description: ["text-gray-60"],
      },
    },
    layout: {
      vertical: {
        base: ["gap-6"],
        caption: ["h-full"],
        footing: ["mt-auto"],
      },
      horizontal: {
        base: ["md:flex-row gap-6 md:gap-12"],
        figureWrapper: ["md:w-1/2"],
        figure: [],
        caption: ["md:w-1/2"],
        footing: ["md:mt-auto"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
    layout: "vertical",
  },
});

export type MediaCardVariantProps = VariantProps<typeof mediaCardVariant>;

export type MediaCardReturnVariantProps = ReturnType<typeof mediaCardVariant>;
