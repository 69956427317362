import { Vector } from "../../ui/vector";
import { Portal } from "../portal";
import { useModal, type UseModalProps } from "./use-modal";

export type ModalProps = UseModalProps;

function Modal(props: ModalProps) {
  const {
    modalRef,
    className,
    children,
    active,
    hideCloseButton,
    handleClose,
    styles,
  } = useModal(props);

  if (!active) return null;

  return (
    <Portal target="modal-portal">
      <div
        aria-hidden={active}
        aria-modal="true"
        className={styles.base({ className })}
        data-component-name="modal"
        role="dialog"
        tabIndex={-1}
      >
        <div className={styles.overlay()}>
          <div className={styles.wrapper()}>
            <div className={styles.content()} ref={modalRef}>
              {!hideCloseButton && (
                <button className={styles.close()} onClick={handleClose}>
                  <Vector className={styles.icon()} name="x-mark" />
                </button>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default Modal;
