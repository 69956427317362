function CodeGroupHeading({ title }: { title?: string }) {
  if (!title) {
    return null;
  }

  return (
    <div className="flex min-h-[calc(theme(spacing.12)+1px)] flex-wrap items-start gap-x-4 border-b px-4 border-gray-800 bg-transparent">
      <h3 className="mr-auto pt-3.5 text-xs font-semibold text-white">
        {title}
      </h3>
    </div>
  );
}

export default CodeGroupHeading;
