import { Text } from "../../ui/text";
import {
  useRichContentCard,
  useRichContentCardProps,
} from "./use-rich-content-card";
import RichContentCardVideo from "./rich-content-card.video";
import { ArtDirection } from "../../util/art-direction";
import { ButtonGroup } from "../../util/button-group";
import { WorkflowDemo } from "../../common/workflow-demo";

export type RichContentCardProps = useRichContentCardProps;

const RichContentCard = (props: RichContentCardProps) => {
  const {
    className,
    sectionName,
    styles,
    theme,
    hasMedia,
    backgroundType,
    data: {
      title,
      subtitle,
      description,
      buttons,
      background,
      backgroundMobile,
      backgroundSize,
      demoJobId,
      demoCTALabel,
    },
  } = useRichContentCard(props);

  return (
    <article
      data-component-name="rich-content-card"
      className={styles.base({ className })}
    >
      <div className={styles.wrapper()}>
        <div className={styles.container()}>
          <div className={styles.caption()}>
            <Text
              tag="h2"
              size="h5"
              theme={theme}
              className={styles.title()}
              weight="medium"
            >
              {title}
            </Text>
            {!!subtitle && (
              <Text
                tag="h3"
                size="subtitle"
                theme={theme}
                className={styles.subtitle()}
                weight="medium"
              >
                {subtitle}
              </Text>
            )}

            {!!description && (
              <Text
                theme={theme}
                className={styles.description()}
                weight="medium"
              >
                {description}
              </Text>
            )}
            <ButtonGroup
              sectionName={sectionName}
              data={{
                buttons,
              }}
              theme={theme}
              align="left"
              className={styles.cta()}
            />
          </div>
          {hasMedia && (
            <div className={styles.media()}>
              <ArtDirection
                fill
                hiddenOn="md"
                className={styles.image()}
                containerClassName={styles.mediaContainer()}
                sizes="(max-width: 768px) 100vw, 50vw"
                desktop={background}
                mobile={backgroundMobile}
              />
              <RichContentCardVideo
                background={background}
                backgroundSize={backgroundSize}
                type={backgroundType}
              />
            </div>
          )}
          {demoJobId ? (
            <div className={styles.demoContainer()}>
              <WorkflowDemo
                data={{
                  demoJobId,
                  runDemoLabel: demoCTALabel,
                  demoCoverImg: background,
                  demoCoverImgAlt: title as string,
                  noFrame: true,
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </article>
  );
};

export default RichContentCard;
