import { tv, type VariantProps } from "../../../../../util/tv";

const trackVariant = tv({
  slots: {
    base: [
      "group",
      "flex flex-col items-center justify-center",
      "shrink-0",
      "px-5 pt-6 pb-5",
      "text-center",
      "[&:not(:last-child)]:border-r",
      "[&:not(:last-child)]:border-gray-700",
    ],
    icon: [
      "w-8 h-8",
      "mb-4",
      "group-hover:text-white",
      "transition-colors duration-300 ease-in-out",
    ],
    name: [
      "group-hover:text-white",
      "transition-colors duration-300 ease-in-out",
    ],
    spectrum: ["mt-auto"],
  },
  variants: {
    isActive: {
      true: {
        icon: ["text-white"],
        name: ["text-gray-15"],
      },
      false: {
        icon: ["text-white/20"],
        name: ["text-white/20"],
      },
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export type TrackVariantProps = VariantProps<typeof trackVariant>;

export { trackVariant };
