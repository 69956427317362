"use client";

import { ShowcaseModule } from "../../types/showcase";
import showcaseOutputs from "./outputs";
import { useShowcaseOutput } from "./use-showcase-output";

const isActiveModule = (currentModuleId: string, moduleId: string) => {
  if (currentModuleId === moduleId) return "opacity-100";

  return "opacity-0 select-none pointer-events-none";
};

type ID = keyof typeof showcaseOutputs;

const ShowcaseOutput = () => {
  const { styles, currentModule, data } = useShowcaseOutput();

  if (!data || !currentModule) return null;

  return (
    <div className={styles.base()}>
      {data.modules.map((item) => {
        const id = item.id as ID;
        const Component = showcaseOutputs?.[id];

        if (!Component) return null;

        return (
          <div
            key={id}
            className={styles.module({
              className: isActiveModule(currentModule?.id, id),
            })}
          >
            {/* @ts-ignore */}
            <Component data={item} />
          </div>
        );
      })}
    </div>
  );
};

export { ShowcaseOutput };
