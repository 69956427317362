import { tv, type VariantProps } from "../../util/tv";

export const showcaseCarouselVariant = tv({
  slots: {
    base: ["w-full", "relative z-30"],
    carousel: ["group", "relative z-50", "w-full", "!overflow-visible"],
    slide: ["!w-36 !h-36", "transition-all duration-300 ease-in-out"],
    nav: ["absolute inset-0 z-30", "m-auto", "w-36 h-full", "select-none"],
    button: [
      "absolute inset-0",
      "m-auto",
      "active:scale-90",
      "transition-all duration-300 ease-in-out",
    ],
  },
});

export type ShowcaseCarouselVariantProps = VariantProps<
  typeof showcaseCarouselVariant
>;

export type ShowcaseCarouselReturnVariantProps = ReturnType<
  typeof showcaseCarouselVariant
>;
