import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { submitHubspotContactSalesForm } from "../../../services/hubspot";
import type { ComponentProps, SectionsContact } from "../../../types";
import type { ContactFields } from "../../../types/forms";
import { contactFormVariant } from "./contact-form.variants";

export type UseContactFormProps = ComponentProps<SectionsContact["form"]>;

export const useContactForm = (props: UseContactFormProps) => {
  const { className = "", data } = props;

  const form = useForm<ContactFields>();
  const styles = contactFormVariant();

  const onSubmit: SubmitHandler<ContactFields> = async (payload) => {
    try {
      await submitHubspotContactSalesForm(payload);
      toast.success(data.messages.success);
      form.reset();
    } catch (error) {
      toast.error(data.messages.failed);
      console.error(error);
    }
  };

  return {
    styles,
    className,
    form,
    onSubmit,
    ...data,
  };
};
