import { type Metadata } from "../../../../types/showcase/metadata";
import { metadataVariant } from "./metadata.variants";

export type UseMetadataProps = {
  data: Metadata;
};

const useMetadata = (props: UseMetadataProps) => {
  const { data } = props;
  const { metadata } = data;

  const styles = metadataVariant();

  return {
    styles,
    metadata,
  };
};

export { useMetadata };
