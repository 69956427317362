import { PropsWithChildren } from "react";
import { lyricVariant } from "./lyric.variants";

export type UseLyricProps = PropsWithChildren<{
  isActive: boolean;
  isCurrentLyric: boolean;
}>;

const useLyric = ({ children, isActive, isCurrentLyric }: UseLyricProps) => {
  const styles = lyricVariant({ isActive });

  return {
    styles,
    children,
    isCurrentLyric: isCurrentLyric,
  };
};

export { useLyric };
