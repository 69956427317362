import { useMemo } from "react";
import type { SectionProps, SectionsRichContentSlider } from "../../../types";
import { richContentSlider } from "./rich-content-slider.variants";

export type useRichContentSliderProps =
  SectionProps<SectionsRichContentSlider> & {
    runDemoLabel: string | null;
    getStartedLabel: string | null;
  };

export const useRichContentSlider = (props: useRichContentSliderProps) => {
  const {
    className = "",
    sectionName = "",
    runDemoLabel,
    getStartedLabel,
    data: { theme = "light", cards = [], ...restData },
  } = props;

  const styles = useMemo(() => richContentSlider({ theme }), [theme]);

  const hasOneCard = cards && cards.length === 1;

  return {
    className,
    sectionName,
    hasOneCard,
    data: {
      theme,
      cards,
      runDemoLabel,
      getStartedLabel,
      ...restData,
    },
    styles,
  };
};
