"use client";

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  target: string;
  children: React.ReactNode;
}

function Portal({ target, children }: PortalProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  const targetElement = document.querySelector(`#${target}`);

  if (mounted && targetElement) {
    return createPortal(children, targetElement);
  }

  return null;
}

export default Portal;
