import NextImage from "next/image";
import { useGalleryCard, type UseGalleryCardProps } from "./use-gallery-card";

export type ModuleCardProps = UseGalleryCardProps;

function ModuleCard(props: ModuleCardProps) {
  const { className, styles, image } = useGalleryCard(props);

  return (
    <div className={styles.base({ className })}>
      <NextImage
        alt={image.alternativeText || "image"}
        className={styles.image()}
        fill
        sizes="100vw"
        src={image.url}
      />
    </div>
  );
}

export default ModuleCard;
