"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import type { SectionsHeroGallery } from "../../../types";
import { GalleryCard } from "../../common/gallery-card";

export interface HeroGallerySwiperProps {
  className?: string;
  gallery: SectionsHeroGallery["gallery"];
}

function HeroGallerySwiper({
  className = "",
  gallery,
}: HeroGallerySwiperProps) {
  return (
    <Swiper
      autoplay={{ delay: 2500 }}
      className={className}
      effect="fade"
      loop
      modules={[Autoplay, EffectFade]}
      simulateTouch={false}
      slidesPerView={1}
      spaceBetween={20}
      speed={1000}
    >
      {gallery.map((image) => (
        <SwiperSlide key={`gallery-${image.id}`}>
          <GalleryCard image={image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default HeroGallerySwiper;
