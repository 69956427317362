import { type Track } from "../../../../../types/showcase/track";
import { useShowcaseState } from "../../../use-showcase-state";
import { trackVariant } from "./track.variants";

export type UseTrackProps = {
  track: string;
  data: Track;
};

const useTrack = (props: UseTrackProps) => {
  const { track, data } = props;

  const { currentModule, setActiveTrack } = useShowcaseState();

  const styles = trackVariant({
    isActive: currentModule.track === track,
  });

  return {
    styles,
    track,
    data,
    setActiveTrack,
  };
};

export { useTrack };
