import { tv, type VariantProps } from "../../../util/tv";

export const quoteCard = tv({
  slots: {
    base: [
      "flex flex-col",
      "rounded-xl",
      "h-full",
      "relative",
      "overflow-hidden",
      "max-w-lg",
    ],
    wrapper: ["z-10", "w-full h-full", "flex flex-col", "p-6", "justify-end"],
    body: ["flex flex-col gap-5 relative"],
    footer: [
      "inline-flex gap-5 justify-between items-center content-center",
      "mt-6",
    ],
    footerDetails: ["flex flex-col gap-1"],
    name: ["not-italic"],
    role: [],
    bgImage: ["rounded-xl", "object-cover"],
    logo: [],
    description: [""],
    quoteBefore: [],
    quoteAfter: [],
  },
  variants: {
    theme: {
      dark: {
        base: "text-white",
        role: "text-gray-20",
      },
      light: {
        base: "text-black",
        role: "text-gray-60",
      },
    },
    appearance: {
      default: {
        base: ["bg-gray-900"],
        wrapper: ["justify-between"],
        body: ["pt-6"],
        quoteBefore: ["w-[74px] h-auto absolute top-0 left-0"],
        quoteAfter: ["w-[74px] h-auto absolute bottom-[-2rem] right-0"],
      },
      photo: {
        wrapper: ["bg-gradient-to-t from-black to-transparent to-[120%]"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type QuoteCardVariantProps = VariantProps<typeof quoteCard>;
