function CodePanelHeading({ label }: { label?: string }) {
  if (!label) {
    return null;
  }

  return (
    <div className="flex h-9 items-center gap-2 border-y border-t-transparent bg-gray-900 px-4 border-b-white/5 bg-white/1">
      <span className="font-mono text-xs text-gray-400">{label}</span>
    </div>
  );
}

export default CodePanelHeading;
