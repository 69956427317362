import { type RefObject, useEffect, useMemo } from "react";
import WaveSurfer, { type WaveSurferOptions } from "wavesurfer.js";
import {
  ShowcaseContainerRef,
  type WaveSurferInstances,
} from "./showcase-context";
import { useShowcaseState } from "./use-showcase-state";

export const useWavesurferInstance = (
  containerRefs: RefObject<ShowcaseContainerRef>,
  tracks: Record<string, string>,
  options: Partial<WaveSurferOptions>
): WaveSurferInstances => {
  const { wavesurfer, setWavesurfer, setWavesurferFrequency } =
    useShowcaseState();

  const flatOptions = useMemo(
    () => Object.entries({ ...options }).flat(),
    [options]
  );

  useEffect(() => {
    if (!containerRefs.current) return;

    const wavesurferInstances: WaveSurferInstances = {};

    Object.entries(containerRefs.current).forEach(([track, container]) => {
      if (container && !wavesurfer[track]) {
        const ws = WaveSurfer.create({
          ...options,
          container,
          url: tracks[track],
        });

        wavesurferInstances[track] = ws;
        setWavesurferFrequency(track, Array(5).fill(2));
      }
    });

    setWavesurfer(wavesurferInstances);

    return () => {
      Object.values(wavesurferInstances).forEach((ws) => ws?.destroy());
    };
  }, [containerRefs, ...flatOptions]);

  return wavesurfer;
};
