import { tv, type VariantProps } from "../../../../util/tv";

const metadataVariant = tv({
  slots: {
    base: ["relative", "w-full h-full", "overflow-hidden"],
    scrollArea: [
      "w-full h-full",
      "grid grid-flow-col",
      "overflow-x-auto overflow-y-hidden",
      "hide-scrollbar",
      "auto-cols-[minmax(calc(100%/3),1fr)] sm:auto-cols-[minmax(calc(100%/4),1fr)]",
    ],
    metadataGroup: [
      "relative",
      "w-full h-full",
      "flex flex-col",
      "px-2",
      "text-center",
      "overflow-y-auto overflow-x-hidden",
      "hide-scrollbar",
      "border-r",
      "border-gray-700",
    ],
    metadataHeading: [
      "w-full",
      "pt-4 pb-2",
      "sticky top-0 z-10",
      'before:content-[""]',
      "before:absolute before:inset-x-0 before:top-0 before:h-[130%]",
      "before:bg-gradient-to-b before:from-black before:from-50% before:to-transparent",
    ],
    metadataTitle: ["relative z-10"],
    metadataList: ["w-full h-auto", "flex flex-col gap-0.5", "pb-3"],
    metadataItem: ["text-white/40", "capitalize"],
  },
});

export type MetadataVariantProps = VariantProps<typeof metadataVariant>;

export { metadataVariant };
