import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import { useShowcaseCard, UseShowcaseCardProps } from "./use-showcase-card";

export type ShowcaseCardProps = UseShowcaseCardProps;

const ShowcaseCard = (props: ShowcaseCardProps) => {
  const { theme, styles, data } = useShowcaseCard(props);
  const { icon, title } = data;

  return (
    <article className={styles.base()} data-component-name="showcase-card">
      <div className={styles.wrapper()}>
        <div className={styles.innerWrapper()}>
          {!!icon && <Vector className={styles.icon()} name={icon} />}
          {!!title && (
            <Text
              theme={theme}
              className={styles.title()}
              tag="h3"
              size="body"
              weight="semibold"
            >
              {title}
            </Text>
          )}
        </div>
        <span className={styles.outerBlock()} />
      </div>
    </article>
  );
};

export default ShowcaseCard;
