import { tv, type VariantProps } from "../../../util/tv";

export const ticker = tv({
  slots: {
    base: ["flex", "h-full w-full"],
    scrollArea: ["flex"],
    group: ["flex"],
    item: ["animate-fade-in", "opacity-0"],
  },
  variants: {
    direction: {
      up: {},
      down: {},
      left: {},
      right: {},
    },
  },
  compoundVariants: [
    {
      direction: ["up", "down"],
      className: {
        scrollArea: ["flex-col", "h-max"],
        group: ["flex-col"],
      },
    },
    {
      direction: ["left", "right"],
      className: {
        scrollArea: ["w-max"],
      },
    },
  ],
});

export type TickerVariantProps = VariantProps<typeof ticker>;

export type TickerReturnVariantProps = ReturnType<typeof ticker>;
