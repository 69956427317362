"use client";

import { RichContentCard } from "../../common/rich-content-card";
import RichContentSliderSwiper from "./rich-content-slider.swiper";
import {
  useRichContentSlider,
  useRichContentSliderProps,
} from "./use-rich-content-slider";
import type { RichContentCard as RichContentCardType } from "../../../types/strapi/models";

export type RichContentSliderProps = useRichContentSliderProps;

const RichContentSlider = (props: RichContentSliderProps) => {
  const { className, sectionName, hasOneCard, data, styles } =
    useRichContentSlider(props);

  const { theme, cards } = data;

  return (
    <section
      data-component-name={sectionName}
      className={styles.base({ className })}
    >
      <div>
        {hasOneCard ? (
          <RichContentCard
            sectionName={sectionName}
            theme={theme}
            data={cards[0] as RichContentCardType}
          />
        ) : (
          <RichContentSliderSwiper
            styles={styles}
            sectionName={sectionName}
            data={data}
          />
        )}
      </div>
    </section>
  );
};

export default RichContentSlider;
