import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import { Avatar } from "../avatar";
import { useReviewCard, type UseReviewCardProps } from "./use-review-card";

export type ReviewCardProps = UseReviewCardProps;

const rates = [1, 2, 3, 4, 5];

function ReviewCard(props: ReviewCardProps) {
  const { className, styles, theme, data, isGhostCard } = useReviewCard(props);
  const { avatar, name, role, description, stars } = data;

  return (
    <article
      className={styles.base({ className })}
      data-component-name="review-card"
    >
      {isGhostCard && !!stars ? (
        <div className={styles.rating()}>
          {rates.map((rating) => (
            <Vector
              className={styles.star({
                className: rating <= stars ? "text-yellow-400" : "text-gray-50",
              })}
              key={`star-${rating}`}
              name="star"
            />
          ))}
        </div>
      ) : null}
      <div className={styles.body()}>
        {!isGhostCard && <Vector className={styles.quote()} name="quote" />}
        <Text
          className={styles.description()}
          size={isGhostCard ? "body" : "description"}
          theme={theme}
          weight="medium"
        >
          {description}
        </Text>
      </div>
      <footer className={styles.author()}>
        {!!avatar && <Avatar image={avatar} size="xl" />}
        <div className={styles.authorDetails()}>
          <Text
            className={styles.name()}
            tag="cite"
            theme={theme}
            weight="semibold"
          >
            {name}
          </Text>
          {!!role && (
            <Text
              className={styles.role()}
              tag="span"
              theme={theme}
              weight="medium"
            >
              {role}
            </Text>
          )}
        </div>
      </footer>
    </article>
  );
}

export default ReviewCard;
