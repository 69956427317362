import NextImage from "next/image";
import NextLink from "next/link";
import type { ModelsMediaCard } from "../../../types";
import { VideoCard } from "../video-card";
import type { MediaCardReturnVariantProps } from "./media-card.variants";

export interface MediaCardFigureProps {
  styles: MediaCardReturnVariantProps;
  data: Pick<ModelsMediaCard, "title" | "cover" | "url">;
  isYoutubeVideo: boolean;
}

function MediaCardFigure({
  styles,
  isYoutubeVideo,
  data,
}: MediaCardFigureProps) {
  const { title, cover, url } = data;

  if (isYoutubeVideo && url) {
    return <VideoCard data={{ title, cover, url }} />;
  }

  if (!cover) return null;

  const poster = (
    <NextImage
      alt={cover.alternativeText || title || ""}
      className={styles.image()}
      fill
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      src={cover.url}
    />
  );

  if (url) {
    return (
      <NextLink href={url} title={title || undefined}>
        {poster}
      </NextLink>
    );
  }

  return poster;
}

export default MediaCardFigure;
