import { type Voice } from "../../../../../types/showcase/voices";
import { useShowcaseState } from "../../../use-showcase-state";
import { voiceVariant } from "./voice.variants";

export type UseVoiceProps = {
  track: string;
  data: Voice;
};

const useVoice = (props: UseVoiceProps) => {
  const { track, data } = props;

  const { currentModule, setActiveTrack } = useShowcaseState();

  const styles = voiceVariant({
    isActive: currentModule.track === track,
  });

  return {
    styles,
    track,
    data,
    setActiveTrack,
  };
};

export { useVoice };
