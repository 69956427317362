import { tv } from "../../../util/tv";

export const heroHighlightVariant = tv({
  slots: {
    base: ["relative", "sm:max-w-sm md:max-w-full sm:mx-auto"],
    figure: [""],
    icon: [
      "absolute inset-0 m-auto",
      "translate-x-[13%] translate-y-[12%]",
      "p-[15%]",
      "w-1/2 h-1/2",
      "text-white",
      "[&>path]:stroke-1",
    ],
  },
  variants: {
    show: {
      false: {
        base: ["hidden"],
      },
      true: {
        base: [],
      },
    },
  },
  defaultVariants: {
    show: false,
  },
});
