"use client";

import { CodeGroup } from "../../common/code";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { TextArea } from "../../ui/textarea";
import {
  useDownloadForm,
  type UseDownloadFormProps,
} from "./use-download-form";

export type DownloadFormProps = UseDownloadFormProps;

function DownloadForm(props: DownloadFormProps) {
  const { styles, className, theme, form, onSubmit, currentFile } =
    useDownloadForm(props);
  const { register, handleSubmit } = form;

  return (
    <div className={styles.base({ className })}>
      <form className={styles.form()} onSubmit={handleSubmit(onSubmit)}>
        <Input
          aria-describedby="full-name-required"
          label="Full name"
          theme={theme}
          {...register("full_name", { required: true })}
        />
        <Input
          aria-describedby="email-required"
          label="E-mail"
          theme={theme}
          type="email"
          {...register("email", { required: true })}
        />
        <Input
          aria-describedby="organization-required"
          label="Organization"
          theme={theme}
          {...register("org", { required: true })}
        />
        <TextArea
          aria-describedby="justification-required"
          label="Justification"
          rows={4}
          theme={theme}
          {...register("justification", { required: true })}
        />
        {!!currentFile && (
          <CodeGroup
            code={currentFile.cite ?? ""}
            title="If you use the file dataset for your research, cite our publication"
          >
            <>{currentFile.cite}</>
          </CodeGroup>
        )}
        <Button
          appearance="solid"
          color="blue"
          fluid="all"
          isLoading={form.formState.isSubmitting}
          type="submit"
        >
          Request download
        </Button>
      </form>
    </div>
  );
}

export default DownloadForm;
