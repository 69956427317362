import type { ComponentProps, WriterType } from "../../../types";
import type { TextProps } from "../../ui/text/text";
import type { AvatarProps } from "../avatar";
import { authorVariant, type AuthorVariantProps } from "./author.variants";

export type UseAuthorProps = ComponentProps<
  WriterType,
  AuthorVariantProps & {
    avatar?: AvatarProps["size"];
    date?: string | null;
    readTime?: string;
    showBio?: boolean;
  }
>;

export const useAuthor = (props: UseAuthorProps) => {
  const {
    className = "",
    theme = "dark",
    avatar = "lg",
    data,
    date,
    showBio = false,
    readTime,
  } = props;

  const styles = authorVariant({ theme });
  const sizeName: TextProps["size"] = date ? "tinyBody" : "h6";

  return {
    className,
    theme,
    styles,
    sizeName,
    sizeAvatar: avatar,
    data,
    date,
    showBio: showBio && !!data.bio,
    readTime,
  };
};
