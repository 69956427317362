"use client";

import { Text } from "../../ui/text";
import { Button } from "../../ui/button";
import { Modal } from "../../util/modal";
import JsonView from "@uiw/react-json-view";
import { darkTheme } from "@uiw/react-json-view/dark";
import { useOutputModal } from "./use-output-modal";

interface OutputReadableModalProps {
  obj: any;
  title?: string;
}

export function OutputReadableModal({ obj, title }: OutputReadableModalProps) {
  const { modalActive, handleShowModal, handleCloseModal } = useOutputModal();

  return (
    <>
      <Button
        appearance="solid"
        size="xxs"
        color="twoGrays"
        onClick={handleShowModal}
      >
        View
      </Button>

      <Modal active={modalActive} handleClose={handleCloseModal}>
        <div className="aspect-w-16 aspect-h-9">
          <div className="w-full h-full overflow-hidden py-6 px-2 bg-gray-600 rounded-xl">
            {!!title && (
              <div className="px-6 py-2">
                <Text size="description" weight="medium">
                  {title}
                </Text>
              </div>
            )}
            <JsonView
              displayDataTypes={false}
              enableClipboard={false}
              style={darkTheme}
              value={obj}
              className="w-full h-full p-4 rounded-xl overflow-auto tiny-scrollbar-dark !bg-gray-600"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
