import useUTMParams from "../../../hooks/use-utm-params";
import type { SectionProps, ModelsMediaCard } from "../../../types";
import {
  mediaCardVariant,
  type MediaCardVariantProps,
} from "./media-card.variants";

export type UseMediaCardProps = SectionProps<
  ModelsMediaCard,
  MediaCardVariantProps
>;

export const useMediaCard = (props: UseMediaCardProps) => {
  const {
    className = "",
    sectionName,
    theme = "dark",
    layout = "vertical",
    data,
  } = props;
  const { id, preTitle, title, description, url, writer } = data;

  const styles = mediaCardVariant({ theme, layout });
  const { appendUTMParams } = useUTMParams();

  const isYoutubeVideo = !!url && url.includes("youtube");
  const isHorizontalLayout = layout === "horizontal";

  const hasContent = !!preTitle || !!title || !!description;
  const showCaption = hasContent || !!writer;

  const getHrefWithUTM = () => {
    if (!url) {
      return null;
    }

    if (isYoutubeVideo) {
      return url;
    }

    return appendUTMParams(url, `${sectionName}_${id}`);
  };

  return {
    className,
    styles,
    theme,
    sectionName,
    isYoutubeVideo,
    isHorizontalLayout,
    showCaption,
    hasContent,
    data: {
      ...data,
      url: getHrefWithUTM(),
    },
  };
};
