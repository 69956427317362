"use client";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Select } from "../../ui/select";
import { TextArea } from "../../ui/textarea";
import { useContactForm, type UseContactFormProps } from "./use-contact-form";

export type ContactFormProps = UseContactFormProps;

function ContactForm(props: ContactFormProps) {
  const {
    styles,
    className,
    form,
    fields,
    placeholders,
    values,
    send,
    onSubmit,
  } = useContactForm(props);

  const { register, handleSubmit } = form;

  return (
    <form
      className={styles.base({ className })}
      id="contact-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        aria-describedby="first-name-required"
        label={fields.firstName}
        placeholder="John"
        {...register("firstName", { required: true })}
      />
      <Input
        aria-describedby="last-name-required"
        label={fields.lastName}
        placeholder="Snow"
        {...register("lastName", { required: true })}
      />
      <Input
        aria-describedby="email-required"
        label={fields.email}
        placeholder="you@example.com"
        type="email"
        {...register("email", { required: true })}
      />
      <Input
        aria-describedby="phone-optional"
        label={fields.phone}
        placeholder="(123) 456-7890"
        type="tel"
        {...register("phone")}
      />
      <Input
        aria-describedby="job-title-required"
        className="sm:col-span-2"
        label={fields.title}
        placeholder="Software Engineer"
        {...register("title", { required: true })}
      />
      <Select
        aria-describedby="primary-interest-required"
        className="sm:col-span-2"
        label={fields.primaryAreaOfInterest}
        options={[
          {
            value: "just-curious",
            label: values.justCurious,
          },
          {
            value: "personal-use",
            label: values.personalUse,
          },
          {
            value: "api-native-clients-and-sdks",
            label: values.apiNativeClientsAndSdks,
          },
          {
            value: "catalog-stem-separation",
            label: values.catalogStemSeparation,
          },
          {
            value: "catalog-data-extraction",
            label: values.catalogDataExtraction,
          },
          {
            value: "catalog-enhancement",
            label: values.catalogEnhancement,
          },
          {
            value: "transcription-or-translation",
            label: values.transcriptionOrTranslation,
          },
          {
            value: "other",
            label: values.other,
          },
        ]}
        placeholder="Select"
        {...register("primaryAreaOfInterest", { required: true })}
      />
      <Input
        aria-describedby="company-name-required"
        label={fields.companyName}
        placeholder="Microsoft"
        {...register("companyName", { required: true })}
      />
      <Select
        aria-describedby="company-size-required"
        label={fields.companySize}
        options={[
          {
            value: "Personal use",
            label: values.personalUse,
          },
          {
            value: "self-employed",
            label: values.selfEmployed,
          },
          {
            value: "2-10",
            label: "2-10",
          },
          {
            value: "11-50",
            label: "11-50",
          },
          {
            value: "51-200",
            label: "51-200",
          },
          {
            value: "201-1000",
            label: "201-1000",
          },
          {
            value: "1001+",
            label: "1001+",
          },
        ]}
        placeholder="Select"
        {...register("companySize", { required: true })}
      />
      <TextArea
        aria-describedby="use-case-required"
        className="sm:col-span-2"
        label={fields.useCase}
        placeholder={placeholders.useCase}
        rows={4}
        {...register("useCase", { required: true })}
      />
      <div className="sm:col-span-2">
        <Button
          appearance="solid"
          color="blue"
          fluid="all"
          isLoading={form.formState.isSubmitting}
          type="submit"
        >
          {send}
        </Button>
      </div>
    </form>
  );
}

export default ContactForm;
