import { tv, type VariantProps } from "../../../util/tv";

export const downloadFormVariant = tv({
  slots: {
    base: ["flex flex-col gap-10", "p-10"],
    form: ["grid grid-cols-1 gap-x-8 gap-y-6"],
    file: ["text-black"],
  },
});

export type DownloadFormVariantProps = VariantProps<typeof downloadFormVariant>;
