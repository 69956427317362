import { Button } from "../../ui/button";
import { Text } from "../../ui/text";
import { useButtonGroup, type UseButtonGroupProps } from "./use-button-group";

export type ButtonGroupProps = UseButtonGroupProps;

function ButtonGroup(props: ButtonGroupProps) {
  const { className, styles, theme, sectionName, data } = useButtonGroup(props);
  const { title, buttons } = data;

  if (buttons.length === 0) {
    return null;
  }

  return (
    <div className={styles.base({ className })}>
      {!!title && (
        <Text
          className={styles.title()}
          size="tag"
          theme={theme}
          uppercase
          weight="semibold"
        >
          {title}
        </Text>
      )}
      <div className={styles.buttons()} role="group">
        {buttons.map((button) => (
          <Button
            appearance={button.appearance}
            color={button.color}
            endIcon={button.endIcon}
            fluid={button.fluid}
            href={button.url}
            image={button.image}
            key={`button-${button.id}`}
            newTab={button.newTab}
            sectionName={`${sectionName}_${button.text}`}
            size={button.size}
            startIcon={button.startIcon}
            subText={button.subText}
            title={button.text}
          >
            {button.text}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default ButtonGroup;
