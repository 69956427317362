import { Compass } from "./compass";
import { useChords, UseChordsProps } from "./use-chords";

export type ChordsProps = UseChordsProps;

const Chords = (props: ChordsProps) => {
  const { styles, chordsRef, bars, actions } = useChords(props);

  return (
    <div className={styles.base()} data-module-output="chords">
      <div key="chords" ref={chordsRef} className={styles.scrollArea()}>
        {bars.map((bar, barIndex) => (
          <Compass
            key={`compass-bar-${bar.id}-${barIndex}`}
            barIndex={barIndex}
            actions={actions}
            bar={bar}
          />
        ))}
      </div>
    </div>
  );
};

export { Chords };
