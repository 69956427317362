import { forwardRef, type Ref } from "react";
import { useInput, type UseInputProps } from "./use-input";

export type InputProps = UseInputProps;

function Input(props: InputProps, ref: Ref<HTMLInputElement>) {
  const { styles, className, fieldId, label, componentProps } = useInput({
    ...props,
    ref,
  });

  return (
    <div className={styles.base({ className })}>
      <label className={styles.label()} htmlFor={fieldId}>
        {label}
      </label>
      <div className={styles.group()}>
        <input className={styles.input()} id={fieldId} {...componentProps} />
      </div>
    </div>
  );
}

export default forwardRef(Input);
