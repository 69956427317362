"use client";

import { Lyric } from "./lyric";
import { useLyrics, UseLyricsProps } from "./use-lyrics";

type LyricsProps = UseLyricsProps;

const Lyrics = (props: LyricsProps) => {
  const { styles, lyricsRef, lyrics, currentTime } = useLyrics(props);

  return (
    <div className={styles.base()} data-module-output="lyrics">
      <div ref={lyricsRef} className={styles.scrollArea()}>
        {lyrics.map((line, index) => (
          <div key={index} className={styles.line()}>
            {line.words.map((word, wordIndex) => (
              <Lyric
                key={wordIndex}
                isActive={currentTime >= word.start}
                isCurrentLyric={
                  currentTime >= word.start && currentTime <= word.end
                }
              >
                {word.word}
              </Lyric>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export { Lyrics };
