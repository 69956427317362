import { tv, type VariantProps } from "../../util/tv";

export const showcaseOutputVariant = tv({
  slots: {
    base: [
      "relative z-10",
      "w-full h-44",
      "rounded-2xl",
      "border border-gray-500",
      "overflow-hidden",
    ],
    module: [
      "w-full h-full",
      "absolute inset-0",
      "animate-opacity duration-500",
    ],
  },
});

export type ShowcaseOutputVariantProps = VariantProps<
  typeof showcaseOutputVariant
>;

export type ShowcaseOutputReturnVariantProps = ReturnType<
  typeof showcaseOutputVariant
>;
