import type { SectionProps, ModelsReviewCard } from "../../../types";
import {
  reviewCardVariant,
  type ReviewCardVariantProps,
} from "./review-card.variants";

export type UseReviewCardProps = SectionProps<
  ModelsReviewCard,
  ReviewCardVariantProps
>;

export const useReviewCard = (props: UseReviewCardProps) => {
  const { className = "", appearance = "card", theme = "dark", data } = props;

  const styles = reviewCardVariant({
    theme,
    appearance,
  });

  const isGhostCard = appearance === "ghost";

  return {
    theme,
    className,
    styles,
    data,
    isGhostCard,
  };
};
