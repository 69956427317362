import { forwardRef, type Ref } from "react";
import { useTextArea, type UseTextAreaProps } from "./use-textarea";

export type TextAreaProps = UseTextAreaProps;

function TextArea(props: TextAreaProps, ref: Ref<HTMLTextAreaElement>) {
  const { styles, className, fieldId, label, componentProps } = useTextArea({
    ...props,
    ref,
  });

  return (
    <div className={styles.base({ className })}>
      <label className={styles.label()} htmlFor={fieldId}>
        {label}
      </label>
      <div className={styles.group()}>
        <textarea className={styles.input()} id={fieldId} {...componentProps} />
      </div>
    </div>
  );
}

export default forwardRef(TextArea);
