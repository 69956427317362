import { tv, type VariantProps } from "../../../util/tv";

const tabsListVariants = tv({
  base: [],
});

const tabsTriggerVariants = tv({
  base: [
    "px-2 py-5 lg:px-5",
    "inline-flex items-center justify-center",
    "whitespace-nowrap",
    "disabled:pointer-events-none disabled:opacity-50",
    "border-b border-transparent",
    "text-gray-40",
    "transition-all",
    "hover:text-white",
    "data-[state=active]:border-white data-[state=active]:text-white",
  ],
});

const tabsContentVariants = tv({
  base: ["py-10"],
});

export type TabsListVariantProps = VariantProps<typeof tabsListVariants>;

export type TabsTriggerVariantProps = VariantProps<typeof tabsTriggerVariants>;

export type TabsContentVariantProps = VariantProps<typeof tabsContentVariants>;

export { tabsListVariants, tabsTriggerVariants, tabsContentVariants };
