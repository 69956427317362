import type { ModelsIconWorkflowCard, ComponentProps } from "../../../types";
import {
  iconWorkflowCardVariant,
  type IconWorkflowCardVariantProps,
} from "./icon-workflow-card.variants";

export type UseIconWorkflowCardProps = ComponentProps<
  ModelsIconWorkflowCard,
  IconWorkflowCardVariantProps
>;

export const useIconWorkflowCard = (props: UseIconWorkflowCardProps) => {
  const { className = "", theme = "dark", data } = props;

  const styles = iconWorkflowCardVariant({ theme });

  return {
    theme,
    className,
    styles,
    data,
  };
};
