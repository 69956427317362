import type { Media } from "../types";

const mediaTypes: Record<string, "image" | "video"> = {
  jpg: "image",
  jpeg: "image",
  png: "image",
  gif: "image",
  webp: "image",
  svg: "image",
  mp4: "video",
  avi: "video",
  mov: "video",
  wmv: "video",
  webm: "video",
};

const getMediaType = (url: string): "invalid" | "image" | "video" => {
  if (!url) return "invalid";

  const ext = url.split(".").pop()?.toLowerCase();

  if (!ext) {
    return "invalid";
  }

  return mediaTypes[ext] || "invalid";
};

const getMedia = (media?: Media | null, fill = false) => {
  if (!media) return;

  const mediaType = getMediaType(media.url);

  if (mediaType === "image") {
    return {
      src: media.url,
      alt: media.alternativeText || "",
      width: fill ? undefined : media.width,
      height: fill ? undefined : media.height,
    };
  }
};

export { getMediaType, getMedia };
