import { tv, type VariantProps } from "../../../../../util/tv";

const lyricVariant = tv({
  slots: {
    base: ["mr-2", "select-none", "transition-all duration-200 ease-in-out"],
  },
  variants: {
    isActive: {
      true: {
        base: ["text-white animate-fadeIn"],
      },
      false: {
        base: ["opacity-30"],
      },
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export type LyricVariantProps = VariantProps<typeof lyricVariant>;

export { lyricVariant };
