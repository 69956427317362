import {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  type RefObject,
} from "react";
import { isBrowser } from "../../../../util/env";
import type {
  ChordsAndSections,
  Chord,
} from "../../../../types/showcase/chords-and-sections";
import { useShowcaseState } from "../../use-showcase-state";
import { chordsVariant } from "./chords.variants";

export type UseChordsProps = {
  data: ChordsAndSections;
};

export type UseChordsReturn = {
  styles: ReturnType<typeof chordsVariant>;
  chordsRef: RefObject<HTMLDivElement>;
  bars: {
    id: number;
    beats: {
      chord: Chord | null;
      isOriginal: boolean;
    }[];
  }[];
  actions: {
    getChordForThisBeat: (bar: number, beat: number) => Chord | undefined;
    isPlayedCompass: (barIndex: number) => boolean;
    isActiveBeat: (barIndex: number, beatIndex: number) => boolean;
  };
};

const DEFAULT_NUM_BEATS = 4;

const useChords = (props: UseChordsProps): UseChordsReturn => {
  const { data } = props;
  const { chords } = data;

  const chordsRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [_, setAudioDuration] = useState(0);
  const [beatDuration, setBeatDuration] = useState(0);
  const { wavesurfer, currentModule } = useShowcaseState();

  const styles = chordsVariant();

  useEffect(() => {
    if (!isBrowser || !currentModule.track) return;

    const audio = wavesurfer[currentModule.track];

    if (!audio) return;

    const handleTimeUpdate = () => setCurrentTime(audio.getCurrentTime());

    const handleLoadMetadata = () => {
      const duraton = audio.getDuration();
      setAudioDuration(duraton);

      const maxBar = Math.max(...chords.map((chord) => chord.end_bar));
      const totalBeats = maxBar * DEFAULT_NUM_BEATS;
      const beatPerSecond = duraton / totalBeats;

      setBeatDuration(beatPerSecond);
    };

    audio.on("ready", handleLoadMetadata);
    audio.on("timeupdate", handleTimeUpdate);

    return () => {
      audio.un("ready", handleLoadMetadata);
      audio.un("timeupdate", handleTimeUpdate);
    };
  }, [wavesurfer, currentModule.track]);

  useEffect(() => {
    if (!chordsRef.current) return;

    const chordsEl = chordsRef.current;

    const activeBeat = chordsEl.querySelector<HTMLElement>(
      "[data-active-beat=true]"
    );

    if (!activeBeat) return;

    const activeBeatTop = activeBeat.offsetTop - chordsEl.offsetTop - 24;

    chordsEl.scrollTo({ top: activeBeatTop, behavior: "smooth" });
  }, [currentTime]);

  useEffect(() => {
    if (!chordsRef.current || currentTime === 0) return;

    const chordsEl = chordsRef.current;

    const activeBeat = chordsEl.querySelector<HTMLElement>(
      "[data-active-beat=true]"
    );

    if (activeBeat) {
      const activeBeatTop = activeBeat.offsetTop - chordsEl.offsetTop - 24;
      chordsEl.scrollTo({ top: activeBeatTop, behavior: "instant" });
    }
  }, []);

  const maxBar = useMemo(
    () => Math.max(...chords.map((chord) => chord.end_bar)),
    [chords]
  );

  const lastBeatInLastBar = useMemo(() => {
    const lastChord = chords.find((chord) => chord.end_bar === maxBar);
    const lastBeatInLastBar = lastChord
      ? lastChord.end_beat
      : DEFAULT_NUM_BEATS;

    return lastBeatInLastBar;
  }, [chords]);

  const chordsByBar = useMemo(() => {
    const chordsByBar: Map<number, Chord[]> = new Map();

    chords.forEach((chord) => {
      for (let bar = chord.start_bar; bar <= chord.end_bar; bar++) {
        if (!chordsByBar.has(bar)) {
          chordsByBar.set(bar, []);
        }

        chordsByBar.get(bar)!.push(chord);
      }
    });

    return chordsByBar;
  }, [chords]);

  const generateBars = useCallback(() => {
    const bars = Array.from({ length: maxBar }, (_, barIndex) => {
      const id = barIndex + 1;
      const currentNumBeats =
        id === maxBar ? lastBeatInLastBar : DEFAULT_NUM_BEATS;

      const chordsInCurrentBar = chordsByBar.get(id) || [];

      const beats = Array.from({ length: currentNumBeats }, (_, beatIndex) => {
        const beatPosition = beatIndex + 1;

        const exactMatchChord = chordsInCurrentBar.find(
          (chord) => chord.start_bar === id && chord.start_beat === beatPosition
        );

        const extendedChord =
          !exactMatchChord && beatPosition === 1
            ? chordsInCurrentBar.find(
                (chord) => chord.start_bar < id && chord.end_bar >= id
              )
            : null;

        const chord = exactMatchChord || extendedChord;
        const isOriginal =
          chord && chord.start_bar === id && chord.start_beat === beatPosition;

        return {
          chord: chord || null,
          isOriginal: !!isOriginal,
        };
      });

      return { id, beats };
    });

    return bars;
  }, [chords]);

  const getChordForThisBeat = (bar: number, beat: number) => {
    return chords.find(
      (chord) => chord.start_bar === bar && chord.start_beat === beat
    );
  };

  const bars = useMemo(() => generateBars(), [chords]);

  const isPlayedCompass = (barIndex: number) => {
    if (!wavesurfer[currentModule.track]) return false;

    return chords.some(() => {
      const barStartTime = barIndex * DEFAULT_NUM_BEATS * beatDuration;

      return currentTime >= barStartTime;
    });
  };

  const isActiveBeat = (barIndex: number, beatIndex: number) => {
    const barStartTime = barIndex * DEFAULT_NUM_BEATS * beatDuration;
    const beatStartTime = barStartTime + beatIndex * beatDuration;

    return (
      currentTime >= beatStartTime && currentTime < beatStartTime + beatDuration
    );
  };

  return {
    styles,
    chordsRef,
    bars,
    actions: {
      getChordForThisBeat,
      isPlayedCompass,
      isActiveBeat,
    },
  };
};

export { useChords };
