import { tv, type VariantProps } from "../../../util/tv";

export const moduleCardVariant = tv({
  slots: {
    base: [
      "group/item",
      "overflow-hidden",
      "rounded-2xl",
      "h-full md:min-h-60",
      "focus-within:outline focus-within:outline-2",
    ],
    link: ["h-full", "p-6", "flex flex-col"],
    title: ["mb-3"],
    description: ["mb-8"],
    cta: ["mt-auto", "flex items-center gap-4"],
    arrow: [
      "-rotate-45",
      "h-5 w-5",
      "transition-transform duration-300",
      "group-hover/item:rotate-0",
      "group-focus-within/item:rotate-0",
    ],
  },
  variants: {
    theme: {
      dark: {
        base: ["bg-gray-600", "focus-within:outline-blue-400/50"],
        icon: ["text-white"],
        title: ["text-white"],
        description: ["text-gray-40"],
        cta: ["text-blue-400"],
      },
      light: {
        base: ["bg-white/10", "focus-within:outline-blue-100/50"],
        icon: ["text-gray-60"],
        title: ["text-gray-60"],
        description: ["text-gray-40"],
        cta: ["text-blue-100"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type ModuleCardVariantProps = VariantProps<typeof moduleCardVariant>;

export type ModuleCardReturnVariantProps = ReturnType<typeof moduleCardVariant>;
