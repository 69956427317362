import { type ForwardedRef } from "react";
import type { InputVariantProps } from "./input.variants";
import { inputVariant } from "./input.variants";

export type UseInputProps = InputVariantProps & {
  ref: ForwardedRef<HTMLInputElement>;
  className?: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const useInput = (props: UseInputProps) => {
  const {
    ref,
    className = "",
    id,
    name,
    label,
    type = "text",
    theme = "dark",
    ...componentProps
  } = props;

  const fieldId = id || name;
  const styles = inputVariant({ theme });

  return {
    className,
    styles,
    fieldId,
    label,
    componentProps: {
      ...componentProps,
      ref,
      name,
      type,
    },
  };
};
