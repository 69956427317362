"use client";

import { useSpectrum, UseSpectrumProps } from "./use-spectrum";

export type SpectrumProps = UseSpectrumProps;

const Spectrum = (props: SpectrumProps) => {
  const { styles, className, frequencies } = useSpectrum(props);

  return (
    <div className={styles.base({ className })}>
      {frequencies.map((freq, index) => {
        return (
          <div
            key={index}
            className={styles.bar()}
            style={{
              height: `${Math.max(freq, 2)}px`,
              transition: "height 700ms ease, border-radius 0.2s ease",
            }}
          />
        );
      })}
    </div>
  );
};

export { Spectrum };
