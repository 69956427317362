"use client";

import useHubspotForm from "../../../hooks/use-hubspot-form";
import { Spinner } from "../../util/spinner";
import { hubspotFormVariant } from "./hubspot-form.variants";

export interface HubspotFormProps {
  formId: string;
}

function HubspotForm({ formId }: HubspotFormProps) {
  const styles = hubspotFormVariant();

  const { loaded, error } = useHubspotForm({
    region: "na1",
    portalId: "44307170",
    formId,
    target: "#hubspot-form-wrapper",
  });

  return (
    <div className={styles.base()}>
      {loaded && !error ? (
        <div className={styles.form()} id="hubspot-form-wrapper" />
      ) : (
        <Spinner color="light" size="md" />
      )}
    </div>
  );
}

export default HubspotForm;
