import { tv, type VariantProps } from "../../../util/tv";

export const richContentCard = tv({
  slots: {
    base: ["max-w-7xl mx-auto px-6 lg:px-8"],
    wrapper: [
      "relative md:p-8 w-full lg:h-[520px] rounded-3xl lg:sticky text-white border-top-gray-20",
    ],
    container: ["grid lg:grid-cols-2 h-full"],
    caption: [
      "rounded-t-3xl md:rounded-none",
      "flex flex-col justify-center gap-3",
      "p-10 sm:p-12 md:p-8 lg:py-20 lg:px-16",
    ],
    title: ["max-w-md"],
    subtitle: ["md:max-w-md"],
    description: ["md:max-w-md"],
    cta: ["mt-4 flex-start"],
    media: [
      "flex flex-col justify-center",
      "h-full relative md:bg-transparent",
    ],
    mediaContainer: [
      "aspect-w-16 aspect-h-12 sm:aspect-h-9 lg:aspect-none",
      "lg:h-full",
    ],
    image: "",
    demoContainer: [
      "flex flex-col justify-center",
      "h-full relative md:bg-transparent",
    ],
  },
  variants: {
    theme: {
      dark: {
        wrapper: ["bg-gray-900", "text-white"],
        title: "text-blue-400",
        subtitle: "text-white",
        description: "text-gray-40",
      },
      light: {
        wrapper: ["bg-gray-900", "text-white"],
        title: "text-blue-400",
        subtitle: "text-white",
        description: "text-gray-40",
      },
    },
    background: {
      cover: {
        image: "object-cover",
      },
      contain: {
        image: "object-contain",
      },
    },
    type: {
      invalid: "",
      image: "",
      video: "",
      demo: {
        demoContainer: ["pr-8"],
        media: ["md:hidden"],
      },
    },
  },
  defaultVariants: {
    background: "contain",
    theme: "dark",
    type: "image",
  },
});

export type RichContentCardVariantProps = VariantProps<typeof richContentCard>;
