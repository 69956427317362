import { type ForwardedRef } from "react";
import type { SelectVariantProps } from "./select.variants";
import { selectVariant } from "./select.variants";

type OptionProps =
  | string
  | number
  | { value: string | number; label: string | number; disabled?: boolean };

export type UseSelectProps = SelectVariantProps & {
  ref: ForwardedRef<HTMLSelectElement>;
  className?: string;
  label?: string;
  options: OptionProps[];
  placeholder?: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const useSelect = (props: UseSelectProps) => {
  const {
    ref,
    className = "",
    theme = "dark",
    id,
    name,
    options = [],
    label,
    ...componentProps
  } = props;

  const fieldId = id || name;
  const styles = selectVariant({ theme });

  return {
    className,
    styles,
    fieldId,
    label,
    options,
    componentProps: {
      ...componentProps,
      ref,
      name,
    },
  };
};
