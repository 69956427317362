const possibleAnimationDelays = ["0.2s", "0.4s", "0.6s"];

export const useAnimationDelay = () => {
  const drawDelay = () => {
    const sort = Math.floor(Math.random() * possibleAnimationDelays.length);
    return possibleAnimationDelays[sort];
  };

  return { drawDelay };
};
