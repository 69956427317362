import CodePanel from "./code-panel";

function CodeGroupPanels({
  children,
  ...props
}: React.ComponentPropsWithoutRef<typeof CodePanel>) {
  return <CodePanel {...props}>{children}</CodePanel>;
}

export default CodeGroupPanels;
