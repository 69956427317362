import { tv, type VariantProps } from "../../../util/tv";

export const reviewCardVariant = tv({
  slots: {
    base: ["flex flex-col"],
    rating: ["flex gap-2 mb-3"],
    star: ["w-4 h-4 shrink-0"],
    body: ["flex flex-col gap-5"],
    quote: ["w-12 h-12 shrink-0"],
    description: [],
    author: ["flex gap-5 mt-auto"],
    authorDetails: ["flex flex-col gap-1 h-full"],
    name: ["not-italic"],
    role: [],
  },
  variants: {
    theme: {
      dark: {
        base: [],
        role: ["text-gray-40"],
      },
      light: {
        base: [],
        role: ["text-gray-100"],
      },
    },
    appearance: {
      ghost: {
        body: ["mb-6"],
        description: [],
        author: ["flex-row"],
      },
      card: {
        base: ["p-10 rounded-2xl h-full"],
        body: ["mb-8"],
        description: [],
        author: ["flex-col"],
      },
    },
  },
  compoundVariants: [
    {
      theme: "dark",
      appearance: "card",
      className: {
        base: ["bg-gray-800"],
        name: ["text-blue-400"],
        quote: ["text-blue-400"],
      },
    },
    {
      theme: "light",
      appearance: "card",
      className: {
        base: ["bg-gray-10"],
        name: ["text-blue-100"],
        quote: ["text-blue-100"],
      },
    },
  ],
  defaultVariants: {
    theme: "dark",
    appearance: "card",
  },
});

export type ReviewCardVariantProps = VariantProps<typeof reviewCardVariant>;

export type ReviewCardReturnVariantProps = ReturnType<typeof reviewCardVariant>;
