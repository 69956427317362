import type { Media } from "../../../types";
import { avatarVariant, type AvatarVariantProps } from "./avatar.variants";

export type UseAvatarProps = AvatarVariantProps & {
  className?: string;
  image: Media;
};

export const useAvatar = (props: UseAvatarProps) => {
  const { className = "", size = "md", image } = props;

  const styles = avatarVariant({ size });

  return {
    className,
    styles,
    image,
  };
};
