"use client";

import {
  type Dispatch,
  type SetStateAction,
  type PropsWithChildren,
  type MutableRefObject,
  createContext,
  useState,
  useRef,
} from "react";
import WaveSurfer from "wavesurfer.js";
import { type ShowcaseData } from "../../types/showcase";

export type ShowcaseContainerRef = Record<string, HTMLDivElement>;

export type WaveSurferInstances = Record<string, WaveSurfer>;

export type WaveSurferState = {
  isReady: boolean;
  isPlaying: boolean;
  currentProgress: number;
  frequencies: Record<string, number[]>;
};

export type ShowcaseState = {
  data?: ShowcaseData;
  current: {
    id: string;
    track: string;
    isActive: boolean;
  }[];
};

type ShowcaseContextProps = {
  containerRefs: MutableRefObject<ShowcaseContainerRef>;
  state: ShowcaseState;
  setState: Dispatch<SetStateAction<ShowcaseState>>;
  wavesurfer: WaveSurferInstances;
  setWavesurfer: Dispatch<SetStateAction<WaveSurferInstances>>;
  wavesurferState: WaveSurferState;
  setWavesurferState: Dispatch<SetStateAction<WaveSurferState>>;
};

const initialShowcaseState: ShowcaseState = {
  data: undefined,
  current: [],
};

const initialWavesurferState: WaveSurferState = {
  isReady: false,
  isPlaying: false,
  currentProgress: 0,
  frequencies: {},
};

const ShowcaseContext = createContext<ShowcaseContextProps>({
  containerRefs: { current: {} },
  state: initialShowcaseState,
  setState: () => {},
  wavesurfer: {},
  setWavesurfer: () => {},
  wavesurferState: initialWavesurferState,
  setWavesurferState: () => {},
});

const ShowcaseProvider = ({
  children,
  data,
}: PropsWithChildren<{ data: ShowcaseData }>) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurferInstances>({});
  const containerRefs = useRef<ShowcaseContainerRef>({});

  const [state, setState] = useState<ShowcaseState>({
    data,
    current: data.modules.map((module, i) => ({
      id: module.id,
      track: `${module.id}-0`,
      isActive: i === 0,
    })),
  });

  const [wavesurferState, setWavesurferState] = useState<WaveSurferState>(
    initialWavesurferState
  );

  return (
    <ShowcaseContext.Provider
      value={{
        containerRefs,
        state,
        setState,
        wavesurfer,
        setWavesurfer,
        wavesurferState,
        setWavesurferState,
      }}
    >
      {children}
    </ShowcaseContext.Provider>
  );
};

export { ShowcaseContext, ShowcaseProvider };
