import { tv, type VariantProps } from "../../../util/tv";

export const workflowDemoVariant = tv({
  slots: {
    container: [
      "relative",
      "hidden lg:block",
      "rounded-2xl",
      "overflow-hidden",
      "border-2",
    ],
    cover: [
      "relative",
      "rounded-2xl",
      "aspect-w-16 aspect-h-9",
      "overflow-hidden",
      'before:content-[""]',
      "before:z-10",
      "before:inset-0",
      "before:absolute",
      "before:w-full before:h-full",
      "before:bg-gray-600/70",
    ],
    image: ["object-cover object-center", "rounded-2xl"],
    btnWrapper: ["flex justify-center items-center", "z-10"],
    frame: ["rounded-2xl"],
    btnModal: [
      "absolute z-10",
      "top-[unset] left-[unset] bottom-4 right-4",
      "w-8 h-8",
      "text-black",
    ],
  },
  variants: {
    theme: {
      dark: {},
      light: {},
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type WorkflowDemoVariantProps = VariantProps<typeof workflowDemoVariant>;
