"use client";

import { ShowcaseProvider } from "./showcase-context";
import { ShowcasePlayer } from "./showcase-player";
import { ShowcaseCarousel } from "./showcase-carousel";
import { ShowcaseOutput } from "./showcase-output";
import { useShowcase, type UseShowcaseProps } from "./use-showcase";

type ShowcaseProps = UseShowcaseProps;

const Showcase = (props: ShowcaseProps) => {
  const { styles, theme, data } = useShowcase(props);

  return (
    <ShowcaseProvider data={data}>
      <div className={styles.base()}>
        <ShowcasePlayer theme={theme} cta={data.cta} />
        <span className={styles.separator()} />
        <ShowcaseCarousel theme={theme} modules={data.modules} />
        <span className={styles.separator()} />
        <ShowcaseOutput />
      </div>
    </ShowcaseProvider>
  );
};

export { Showcase };
