import { useCallback, useMemo, useRef } from "react";
import { useShowcaseState } from "./use-showcase-state";
import WaveSurfer from "wavesurfer.js";

const FREQUENCY_COUNT = 5;
const SPECTRUM_HEIGHT = 12;

export const useWavesurferSpectrum = () => {
  const { wavesurfer, setWavesurferFrequency, currentModule } =
    useShowcaseState();

  const audioContextRef = useRef<Record<string, AudioContext>>({});
  const analyserRef = useRef<Record<string, AnalyserNode>>({});
  const sourceNodeRef = useRef<Record<string, MediaElementAudioSourceNode>>({});

  const initSpectrum = useCallback(
    (ws: WaveSurfer, track: string, animationFrameId: number | null) => {
      if (!audioContextRef.current[track]) {
        audioContextRef.current[track] = new window.AudioContext();
      }

      const audioContext = audioContextRef.current[track];

      if (!analyserRef.current[track]) {
        analyserRef.current[track] = audioContext.createAnalyser();
        analyserRef.current[track].fftSize = 128;
      }

      if (!sourceNodeRef.current[track]) {
        try {
          sourceNodeRef.current[track] = audioContext.createMediaElementSource(
            ws.getMediaElement()
          );
          sourceNodeRef.current[track].connect(analyserRef.current[track]);
          analyserRef.current[track].connect(audioContext.destination);
        } catch (error) {
          console.log(error);
        }
      }

      const analyser = analyserRef.current[track];

      if (audioContext.state === "suspended") {
        audioContext.resume();
      }

      if (animationFrameId === null) {
        updateFrequencies(track, analyser, animationFrameId);
      }
    },
    [wavesurfer]
  );

  const startSpectrum = useCallback(
    (track: string, animationFrameId: number | null) => {
      const analyser = analyserRef.current[track];

      if (analyser) {
        updateFrequencies(track, analyser, animationFrameId);
      }

      if (track === currentModule.track) {
        const audioContext = audioContextRef.current[track];

        if (audioContext && audioContext.state === "suspended") {
          audioContext.resume();
        }
      }
    },
    [wavesurfer]
  );

  const stopSpectrum = useCallback(
    (track: string, animationFrameId: number | null, finish = false) => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;

        if (finish) {
          setWavesurferFrequency(track, Array(FREQUENCY_COUNT).fill(2));
        }
      }
    },
    [wavesurfer]
  );

  const updateFrequencies = (
    track: string,
    analyser: AnalyserNode,
    animationFrameId: number | null
  ) => {
    const freqData = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(freqData);

    const maxHeight = SPECTRUM_HEIGHT;
    const newFrequencies = Array(FREQUENCY_COUNT)
      .fill(0)
      .map((_, i) => Math.min((freqData[i]! / 255) * maxHeight, maxHeight));

    setWavesurferFrequency(track, newFrequencies);

    animationFrameId = requestAnimationFrame(() =>
      updateFrequencies(track, analyser, animationFrameId)
    );
  };

  return useMemo(
    () => ({
      audioContextRef,
      analyserRef,
      sourceNodeRef,
      initSpectrum,
      startSpectrum,
      stopSpectrum,
    }),
    [currentModule.track]
  );
};
