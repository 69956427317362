import { useMemo } from "react";
import type { ComponentProps, Media } from "../../../types";
import { QuoteCardVariantProps, quoteCard } from "./quote-card.variants";

export type QuoteCard = {
  id: number;
  name: string | null;
  role: string | null;
  description: string;
  avatar: Media | null;
  logo: Media | null;
};

export type useQuoteCardProps = ComponentProps<
  QuoteCard,
  QuoteCardVariantProps
>;

export const useQuoteCard = (props: useQuoteCardProps) => {
  const {
    className = "",
    theme = "dark",
    data: { avatar, ...restData },
  } = props;

  const appearance = avatar ? "photo" : "default";
  const styles = useMemo(() => quoteCard({ theme, appearance }), [theme]);

  return {
    theme,
    className,
    data: {
      avatar,
      ...restData,
    },
    styles,
  };
};
