import { Chords } from "./chords";
import { Lyrics } from "./lyrics";
import { Tracks } from "./tracks";
import { Metadata } from "./metadata";
import { Voices } from "./voices";

const showcaseOutputs = {
  ["chords-and-sections"]: Chords,
  ["lyrics"]: Lyrics,
  ["mastering"]: Tracks,
  ["metadata"]: Metadata,
  ["stems"]: Tracks,
  ["voice-swapping"]: Voices,
};

export { Chords, Lyrics, Tracks, Metadata, Voices };

export type ShowcaseOutput = keyof typeof showcaseOutputs;

export default showcaseOutputs;
