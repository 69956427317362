import { tv, type VariantProps } from "../../../util/tv";

export const galleryVariant = tv({
  slots: {
    base: ["relative", "w-full", "pb-4"],
    figure: ["aspect-w-16 aspect-h-9", "m-0"],
    image: ["object-cover", "m-0", "bg-black"],
    nav: ["flex", "absolute bottom-10 right-10 z-50"],
  },
});

export type GalleryVariantProps = VariantProps<typeof galleryVariant>;
