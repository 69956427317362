import { tv, type VariantProps } from "../../../util/tv";

export const richContentSlider = tv({
  slots: {
    base: ["relative", "w-full h-full", "overflow-hidden", "py-12 md:pt-0"],
    bullet: [
      "flex overflow-hidden",
      "!w-2.5 !h-2.5",
      "!bg-gray-60",
      "rounded-full",
      "-indent-20",
    ],
    bullets: [
      "w-full",
      "flex md:hidden justify-center",
      "z-40 !inset-x-0",
      "mt-4",
    ],
    nav: [
      "hidden md:flex",
      "absolute z-40 !inset-x-0",
      "md:bottom-8 mx-auto",
      "max-w-7xl",
      "px-6 lg:px-8",
      "rtl:md:flex-row-reverse rtl:md:justify-end",
    ],
    card: ["flex h-auto"],
    cardCaption: ["min-h-[390px] sm:min-h-[340px] md:min-h-[auto]"],
    innerCard: [],
  },
  variants: {
    theme: {
      dark: {
        base: "bg-black",
      },
      light: {
        base: "bg-gray-10",
      },
    },
  },
  defaultVariants: {
    theme: "light",
  },
});

export const richContentSliderNav = tv({
  slots: {
    base: ["hidden lg:block", "mt-8 mb-8", "sticky"],
    list: ["flex justify-center gap-4"],
    bubble: [
      "absolute left-0 bottom-0",
      "h-12 border rounded-[50vh]",
      "origin-[left_center]",
    ],
    anchor: [
      "flex justify-center items-center",
      "font-medium",
      "h-12 px-5",
      "text-display-12 lg:text-display-14",
      "whitespace-nowrap",
      "cursor-pointer",
    ],
  },
  variants: {
    theme: {
      dark: {
        buble: ["border-white"],
        anchor: ["text-white"],
      },
      light: {
        buble: ["bg-gray-700"],
        anchor: ["text-gray-700"],
      },
    },
  },
});

export type RichContentSliderVariantProps = VariantProps<
  typeof richContentSlider
>;

export type RichContentSliderVariantReturnProps = ReturnType<
  typeof richContentSlider
>;
