"use client";

import { Button } from "../ui/button";
import { Vector } from "../ui/vector";
import { Spinner } from "../util/spinner";
import {
  useShowcasePlayer,
  type UseShowcasePlayerProps,
} from "./use-showcase-player";

type ShowcasePlayerProps = UseShowcasePlayerProps;

const ShowcasePlayer = (props: ShowcasePlayerProps) => {
  const { styles, theme, containerRefs, tracks, wavesurfer, cta } =
    useShowcasePlayer(props);

  const { currentTrack, isReady, isPlaying, playPauseAll } = wavesurfer;

  return (
    <div className={styles.base()}>
      <button
        className={styles.playPlause()}
        onClick={playPauseAll}
        disabled={!isReady}
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        <Vector
          className={styles.playPauseIcon()}
          name={isPlaying ? "pause" : "play-audio"}
        />
      </button>
      <div className={styles.waves()}>
        {!isReady && (
          <Spinner
            color={theme === "dark" ? "light" : "dark"}
            className={styles.spinner()}
          />
        )}
        <div className={styles.wavesGroup()}>
          {Object.entries(tracks).map(([id]) => (
            <div
              key={id}
              ref={(el) => {
                if (!el) return;
                containerRefs.current[id] = el;
              }}
              className={styles.wave({
                className: currentTrack === id ? "block" : "hidden",
              })}
            />
          ))}
        </div>
        <Button
          className={styles.cta()}
          appearance="link"
          color={theme === "dark" ? "white" : "black"}
          size="sm"
          href={cta.url}
          title={cta.text}
        >
          {cta.text}
        </Button>
      </div>
    </div>
  );
};

export { ShowcasePlayer };
