"use client";

import YouTube from "react-youtube";
import {
  useYoutubeVideo,
  type UseYoutubeVideoProps,
} from "./use-youtube-video";

export type YoutubeVideoProps = UseYoutubeVideoProps;

function YoutubeVideo(props: YoutubeVideoProps) {
  const { className, containerClassName, youtubeId, styles } =
    useYoutubeVideo(props);

  if (!youtubeId) {
    return null;
  }

  return (
    <YouTube
      className={styles.video({ className: containerClassName })}
      iframeClassName={styles.base({ className })}
      opts={{
        playerVars: {
          color: "white",
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
      }}
      videoId={youtubeId}
    />
  );
}

export default YoutubeVideo;
