import { Theme } from "../../types";
import { ShowcaseData } from "../../types/showcase";
import { showcaseVariant } from "./showcase.variants";

export type UseShowcaseProps = {
  theme: Theme;
  data: ShowcaseData;
};

export const useShowcase = (props: UseShowcaseProps) => {
  const { theme = "dark", data } = props;

  const styles = showcaseVariant();

  return {
    styles,
    theme,
    data,
  };
};
