import { tv, type VariantProps } from "../../../util/tv";

export const artDirection = tv({
  slots: {
    mobile: ["select-none pointer-events-none"],
    desktop: ["select-none pointer-events-none"],
  },
  variants: {
    hiddenOn: {
      sm: {},
      md: {},
      lg: {},
      xl: {},
    },
    hasMobileMedia: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      hiddenOn: "sm",
      hasMobileMedia: true,
      className: {
        mobile: ["block sm:hidden"],
        desktop: ["hidden sm:block"],
      },
    },
    {
      hiddenOn: "md",
      hasMobileMedia: true,
      className: {
        mobile: ["block md:hidden"],
        desktop: ["hidden md:block"],
      },
    },
    {
      hiddenOn: "lg",
      hasMobileMedia: true,
      className: {
        mobile: ["block lg:hidden"],
        desktop: ["hidden lg:block"],
      },
    },
    {
      hiddenOn: "xl",
      hasMobileMedia: true,
      className: {
        mobile: ["block xl:hidden"],
        desktop: ["hidden xl:block"],
      },
    },
  ],
  defaultVariants: {
    hasMobileMedia: true,
    hiddenOn: "sm",
  },
});

export type ArtDirectionVariantProps = Omit<
  VariantProps<typeof artDirection>,
  "hasMobileMedia"
>;
