import { tv, type VariantProps } from "../../../../util/tv";

const voicesVariant = tv({
  slots: {
    base: ["relative", "w-full h-full", "overflow-hidden"],
    scrollArea: [
      "w-full h-full",
      "grid grid-flow-col",
      "overflow-x-auto overflow-y-hidden",
      "hide-scrollbar",
    ],
  },
  variants: {
    gridSize: {
      1: {
        scrollArea: ["grid-cols-1"],
      },
      2: {
        scrollArea: ["grid-cols-2"],
      },
      3: {
        scrollArea: ["grid-cols-3"],
      },
      4: {
        scrollArea: ["grid-cols-4"],
      },
      5: {
        scrollArea: [
          "auto-cols-[minmax(calc(100%/3),1fr)] xs:auto-cols-[minmax(calc(100%/4),1fr)] sm:auto-cols-[minmax(calc(100%/5),1fr)]",
        ],
      },
    },
  },
  defaultVariants: {
    gridSize: 5,
  },
});

export type VoicesVariantProps = VariantProps<typeof voicesVariant>;

export { voicesVariant };
