import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import {
  useIconWorkflowCard,
  type UseIconWorkflowCardProps,
} from "./use-icon-workflow-card";

export type IconWorkflowCardProps = UseIconWorkflowCardProps;

function IconWorkflowCard(props: IconWorkflowCardProps) {
  const { className, styles, theme, data } = useIconWorkflowCard(props);
  const { icon, title, description } = data;

  return (
    <div
      className={styles.base({ className })}
      data-component-name="icon-workflow-card"
    >
      <Vector className={styles.icon()} name={icon} />
      <div className={styles.content()}>
        <Text
          className={styles.title()}
          size="description"
          theme={theme}
          weight="semibold"
        >
          {title}
        </Text>
        <Text className={styles.description()} theme={theme} weight="medium">
          {description}
        </Text>
      </div>
    </div>
  );
}

export default IconWorkflowCard;
