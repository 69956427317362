import { type Voices } from "../../../../types/showcase/voices";
import { voicesVariant } from "./voices.variants";

export type UseVoicesProps = {
  data: Voices;
};

const useVoices = (props: UseVoicesProps) => {
  const { data } = props;
  const { id, tracks } = data;

  const styles = voicesVariant({
    gridSize: Math.min(Math.max(tracks.length, 1), 5) as 1 | 2 | 3 | 4 | 5,
  });

  return {
    styles,
    moduleId: id,
    tracks,
  };
};

export { useVoices };
