"use client";

import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import type { ModelsIconWorkflowCard, Theme } from "../../../types";
import { splitArray } from "../../../util/arr";
import { IconWorkflowCard } from "../../common/icon-workflow-card";
import { Ticker } from "../../util/ticker";

interface WorkflowsAnimationGridProps {
  theme: Theme;
  workflows?: ModelsIconWorkflowCard[];
}

const breakpoints = {
  mobile: {
    cols: 1,
    speed: 15,
  },
  tablet: {
    cols: 2,
    speed: 10,
  },
  desktop: {
    cols: 3,
    speed: 5,
  },
};

type GridBreakpoint = keyof typeof breakpoints;

function WorkflowsAnimationGrid({
  theme,
  workflows = [],
}: WorkflowsAnimationGridProps) {
  const [screen, setScreen] = useState<GridBreakpoint>();
  const [cols, setCols] = useState<ModelsIconWorkflowCard[][]>([]);
  const { width } = useWindowSize();

  useEffect(() => {
    let screenType: GridBreakpoint = "mobile";

    if (width >= 1024) {
      screenType = "desktop";
    } else if (width >= 640) {
      screenType = "tablet";
    }

    setScreen(screenType);
  }, [width]);

  useEffect(() => {
    screen && setCols(splitArray(workflows, breakpoints[screen].cols));
  }, [screen, workflows]);

  if (!screen) return null;

  const odd = breakpoints[screen].speed;
  const even = breakpoints[screen].speed;

  return cols.map((col, i) => (
    <Ticker
      direction="up"
      key={`workflow-col-${i}`}
      spaceBetween="gap-8"
      speed={i % 2 === 0 ? even / 2 : odd}
    >
      {col.map((item, itemIndex) => (
        <IconWorkflowCard data={item} key={itemIndex} theme={theme} />
      ))}
    </Ticker>
  ));
}

export default WorkflowsAnimationGrid;
