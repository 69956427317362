"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import type { SectionsReviewCarousel, Theme } from "../../../types";
import { ReviewCard } from "../../common/review-card";
import { SwiperNav } from "../../util/swiper-nav";

export interface ReviewCarouselSwiperProps {
  className?: string;
  navTheme: Theme;
  navClassName?: string;
  sectionName: string;
  data: SectionsReviewCarousel;
}

function ReviewCarouselSwiper({
  className,
  navTheme,
  navClassName,
  sectionName,
  data,
}: ReviewCarouselSwiperProps) {
  const { id, theme, cardAppearance, reviews } = data;

  const swiperId = `${sectionName}-${id}`;

  return (
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        720: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      }}
      className={className}
      modules={[Navigation]}
      navigation={{
        prevEl: `.prev-carousel-${swiperId}`,
        nextEl: `.next-carousel-${swiperId}`,
      }}
      simulateTouch={false}
      slidesPerView={3}
      spaceBetween={32}
    >
      {reviews.map((card) => (
        <SwiperSlide className="!h-auto" key={`review-card-${card.id}`}>
          <ReviewCard
            appearance={cardAppearance}
            data={card}
            sectionName={sectionName}
            theme={theme}
          />
        </SwiperSlide>
      ))}
      <SwiperNav className={navClassName} id={swiperId} theme={navTheme} />
    </Swiper>
  );
}

export default ReviewCarouselSwiper;
