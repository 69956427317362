/* eslint-disable react-hooks/exhaustive-deps -- activate when visible */
import type { CSSProperties } from "react";
import { Children, useCallback, useEffect, useRef, useState } from "react";
import { AnimationProps, useInView } from "motion/react";
import { useWindowSize } from "react-use";
import { useAnimationDelay } from "../../../hooks/use-animation-delay";
import { ticker } from "./ticker.variants";

export interface UseTickerProps {
  className?: string;
  spaceBetween?: string;
  children: React.ReactNode;
  direction?: "left" | "right" | "up" | "down";
  speed?: number;
}

const directions = {
  left: -1,
  right: 1,
  up: -1,
  down: 1,
};

export const useTicker = (props: UseTickerProps) => {
  const {
    className = "",
    spaceBetween = "gap-4",
    children,
    direction = "left",
    speed = 1,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);

  const [size, setSize] = useState({ x: 0, y: 0 });
  const [fakeItems, setFakeItems] = useState(1);
  const isVisible = useInView(wrapperRef, { once: true });
  const { drawDelay } = useAnimationDelay();
  const { width: windowWidth } = useWindowSize();

  const styles = ticker({ direction });

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const items = itemsRef.current;

    if (!wrapper || !items) return;

    const { width, height } = items.getBoundingClientRect();

    setSize({ x: width, y: height });

    if (["left", "right"].includes(direction)) {
      setFakeItems(Math.max(Math.ceil((2 * wrapper.clientWidth) / width), 1));
    }

    if (["up", "down"].includes(direction)) {
      setFakeItems(Math.max(Math.ceil((2 * wrapper.clientHeight) / height), 1));
    }
  }, [isVisible, direction, windowWidth]);

  const getAnimation = useCallback(() => {
    const axis = ["left", "right"].includes(direction) ? "x" : "y";

    return {
      [axis]: [0, size[axis] * directions[direction]],
      transition: {
        ease: "linear",
        repeat: Infinity,
        duration: speed * 10,
      },
    } as AnimationProps["animate"];
  }, [isVisible, direction, speed, size]);

  const getScrollAreaStyle = useCallback(() => {
    const style: CSSProperties = {};

    if (["left", "right"].includes(direction)) {
      style.marginLeft = `-${size.x * fakeItems}px`;
    } else {
      style.marginTop = `-${size.y * fakeItems}px`;
    }

    return style;
  }, [isVisible, direction, fakeItems, size]);

  return {
    className,
    styles,
    wrapperRef,
    itemsRef,
    isVisible,
    spaceBetween,
    fakeItems,
    getAnimation,
    getScrollAreaStyle,
    drawDelay,
    children: Children.toArray(children),
  };
};
