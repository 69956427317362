import NextLink from "next/link";
import type { ModelsMediaCard, Theme } from "../../../types";
import { Author } from "../author";
import { Text } from "../../ui/text";
import type { MediaCardReturnVariantProps } from "./media-card.variants";

export interface MediaCardCaptionProps {
  styles: MediaCardReturnVariantProps;
  theme: Theme;
  data: ModelsMediaCard;
  isYoutubeVideo: boolean;
  isHorizontalLayout: boolean;
  hasContent: boolean;
}

function MediaCardCaption({
  styles,
  isYoutubeVideo,
  isHorizontalLayout,
  hasContent,
  theme,
  data,
}: MediaCardCaptionProps) {
  const { postedAt, preTitle, title, description, url, writer } = data;

  const renderText = (
    text: string | null | undefined,
    size: "h5" | "subtitle" | "body",
    tag: "p" | "h3",
    className = "",
    linkClassName = ""
  ) => {
    if (text) {
      return (
        <Text
          className={className}
          size={size}
          tag={tag}
          theme={theme}
          weight={tag === "h3" ? "semibold" : "medium"}
        >
          {url && !isYoutubeVideo ? (
            <NextLink
              className={linkClassName}
              href={url}
              tabIndex={-1}
              title={title}
            >
              {text}
            </NextLink>
          ) : (
            text
          )}
        </Text>
      );
    }

    return null;
  };

  return (
    <div className={styles.caption()}>
      {hasContent ? (
        <header className={styles.heading()}>
          {!!preTitle && !isHorizontalLayout && (
            <Text
              className={styles.preTitle()}
              size="tinyBody"
              theme={theme}
              weight="semibold"
            >
              {preTitle}
            </Text>
          )}
          {renderText(
            title,
            isHorizontalLayout ? "h5" : "subtitle",
            "h3",
            styles.title()
          )}
          {renderText(description, "body", "p", styles.description())}
        </header>
      ) : null}
      {!!writer && (
        <footer className={styles.footing()}>
          <Author data={writer} date={postedAt} theme={theme} />
        </footer>
      )}
    </div>
  );
}

export default MediaCardCaption;
