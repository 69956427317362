"use client";

import { Button } from "../../ui/button";
import { Modal } from "../../util/modal";
import { useOutputModal } from "./use-output-modal";

interface OutputVideoModalProps {
  videoUrl: string;
}

export function OutputVideoModal({ videoUrl }: OutputVideoModalProps) {
  const { modalActive, handleShowModal, handleCloseModal } = useOutputModal();

  return (
    <>
      <Button
        appearance="solid"
        size="xxs"
        color="twoGrays"
        onClick={handleShowModal}
      >
        Watch
      </Button>

      <Modal
        active={modalActive}
        handleClose={handleCloseModal}
        className="bg-black"
      >
        <div className="aspect-w-16 aspect-h-9">
          <div className="p-4 bg-black rounded-3xl">
            <video className="aspect-w-16 aspect-h-9" controls>
              <source src={`${videoUrl}`} type="video/mp4" />
            </video>
          </div>
        </div>
      </Modal>
    </>
  );
}
