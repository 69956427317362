import { useState } from "react";
import type { ComponentProps, WorkflowDemoParams } from "../../../types";
import {
  type WorkflowDemoVariantProps,
  workflowDemoVariant,
} from "./workflow-demo.variants";

export type UseWorkflowDemoProps = ComponentProps<
  WorkflowDemoParams,
  WorkflowDemoVariantProps
>;

const FALLBACK_COVER_IMG = "/bg-demo-workflow.png";

export const useWorkflowDemo = (props: UseWorkflowDemoProps) => {
  const { className = "", theme = "dark", data } = props;
  const {
    demoJobId,
    demoCoverImg,
    demoCoverImgAlt = "",
    runDemoLabel,
    noFrame = false,
  } = data;

  const [loadFrame, setloadFrame] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  const styles = workflowDemoVariant({ theme });

  const demoUrl = `https://music.ai/dash/demos/workflow-preview/${demoJobId}`;

  const handleCTAClick = () => {
    if (noFrame) {
      setModalActive(true);
      return;
    }

    setloadFrame(true);
  };

  const handleShowModal = () => {
    setModalActive(true);
  };

  const handleCloseModal = () => {
    setModalActive(false);
  };

  const coverImg = demoCoverImg?.url || FALLBACK_COVER_IMG;

  return {
    theme,
    className,
    styles,
    data,
    loadFrame,
    noFrame,
    handleCTAClick,
    demoUrl,
    demoCoverImg: coverImg,
    demoCoverImgAlt,
    runDemoLabel,
    modalActive,
    handleShowModal,
    handleCloseModal,
  };
};
