import NextImage from "next/image";
import {
  useArtDirection,
  type UseArtDirectionProps,
} from "./use-art-direction";

export type ArtDirectionProps = UseArtDirectionProps;

function ArtDirection(props: ArtDirectionProps) {
  const {
    styles,
    className,
    containerClassName,
    shouldRender,
    desktop,
    mobile,
    componentProps,
  } = useArtDirection(props);

  if (!shouldRender) return null;

  return (
    <>
      {desktop ? (
        <div className={styles.desktop({ className: containerClassName })}>
          <NextImage
            {...componentProps}
            {...desktop}
            className={className}
            quality={85}
          />
        </div>
      ) : null}
      {mobile ? (
        <div className={styles.mobile({ className: containerClassName })}>
          <NextImage
            {...componentProps}
            {...mobile}
            className={className}
            quality={85}
          />
        </div>
      ) : null}
    </>
  );
}

export default ArtDirection;
