import { useLocalStorage } from "react-use";
import type { ComponentProps, ModelsCigarbar } from "../../../types";
import { announcementVariant } from "./announcement.variants";
import { useEffect, useState } from "react";

export type UseAnnouncementProps = ComponentProps<ModelsCigarbar>;

export const useAnnouncement = (props: UseAnnouncementProps) => {
  const { className = "", data } = props;

  const [show, setShow] = useState(false);
  const [closed, setClosed] = useLocalStorage("mai::close_annoucement");

  const styles = announcementVariant({
    isShow: show,
  });

  useEffect(() => {
    if (!closed) {
      setShow(true);
    }
  }, [closed]);

  const closeAnnouncement = () => {
    setShow(false);
    setClosed(true);
  };

  return {
    className,
    styles,
    show,
    closeAnnouncement,
    data,
  };
};
