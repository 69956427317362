import { tv, type VariantProps } from "../../../util/tv";

export const blogListVariant = tv({
  slots: {
    base: [
      "relative",
      "pt-28 pb-14 md:pt-40 md:pb-24 3xl:py-48",
      "overflow-hidden",
      'after:content-[""]',
      "after:absolute after:bottom-0 after:left-0",
      "after:w-full after:h-96",
      "after:bg-gradient-to-t",
      "after:from-[-120%]",
      "after:to-transparent after:to-[80%]",
    ],
    wrapper: ["container-fluid", "relative z-20"],
    heading: ["text-center", "mb-8"],
    preTitle: ["mb-3"],
    title: [],
    description: ["mt-5", "mx-auto"],
    categories: ["hide-scrollbar", "overflow-x-auto", "relative z-20"],
    list: [
      "inline-flex",
      "md:flex justify-center gap-2",
      "py-2",
      "px-8",
      "whitespace-nowrap",
      "snap-x snap-mandatory",
    ],
    content: ["container-fluid", "relative z-20", "mt-10 sm:mt-14"],
    footing: [],
  },
  variants: {
    theme: {
      dark: {
        base: ["bg-black", "after:from-blue-400/40"],
        preTitle: "text-blue-400",
        description: "text-gray-60",
      },
      light: {
        base: ["bg-white", "after:from-blue-100/40"],
        preTitle: "text-blue-100",
        description: "text-gray-60",
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export const blogListGridVariant = tv({
  slots: {
    base: [],
    content: ["grid grid-cols-12 gap-y-12 sm:gap-10"],
    highlight: ["col-span-12"],
    card: ["col-span-12 sm:col-span-6 lg:col-span-4"],
    footing: ["w-full", "flex items-center justify-center", "mt-20"],
  },
});

export type BlogListVariantProps = VariantProps<typeof blogListVariant>;

export type BlogListReturnVariantProps = ReturnType<typeof blogListVariant>;
