import { Track } from "./track";
import { useTracks, UseTracksProps } from "./use-tracks";

type TracksProps = UseTracksProps;

const Tracks = (props: TracksProps) => {
  const { styles, moduleId, tracks } = useTracks(props);

  return (
    <div className={styles.base()} data-module-output="tracks">
      <div className={styles.scrollArea()}>
        {tracks.map(({ ...track }, i) => (
          <Track key={track.id} track={`${moduleId}-${i}`} data={track} />
        ))}
      </div>
    </div>
  );
};

export { Tracks };
