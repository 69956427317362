import { extractYouTubeId } from "../../../util/youtube";
import type { YoutubeVideoVariantProps } from "./youtube-video.variants";
import { youtubeVideo } from "./youtube-video.variants";

export type UseYoutubeVideoProps = YoutubeVideoVariantProps & {
  className?: string;
  containerClassName?: string;
  url: string;
};

export const useYoutubeVideo = (props: UseYoutubeVideoProps) => {
  const { className = "", containerClassName = "", url } = props;

  const styles = youtubeVideo();

  const youtubeId = extractYouTubeId(url);

  return {
    className,
    containerClassName,
    youtubeId,
    styles,
  };
};
