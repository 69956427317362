"use client";

import type { LinksLink } from "../../../types";
import { Button } from "../../ui/button";

export interface IconCardProps {
  sectionName?: string;
  title: string;
  link: LinksLink;
}

function IconCardButton({ sectionName, title, link }: IconCardProps) {
  return (
    <Button
      appearance="link"
      color="blue"
      href={link.url}
      onClick={link.onClick}
      sectionName={`${sectionName}_${title}`}
      startIcon={link.startIcon}
      title={link.text}
    >
      {link.text}
    </Button>
  );
}

export default IconCardButton;
