import { type UseChordsReturn } from "../use-chords";
import { compassVariant } from "./compass.variants";

export type UseCompassProps = {
  barIndex: number;
  bar: UseChordsReturn["bars"][number];
  actions: UseChordsReturn["actions"];
};

const useCompass = ({ barIndex, bar, actions }: UseCompassProps) => {
  const styles = compassVariant();

  const barId = bar.id;

  return {
    styles,
    barIndex,
    barId,
    bar,
    actions,
  };
};

export { useCompass };
