import { useMemo } from "react";
import type { ImageProps } from "next/image";
import { getMedia } from "../../../util/media";
import type { Media } from "../../../types";
import {
  artDirection,
  type ArtDirectionVariantProps,
} from "./art-direction.variants";

export type UseArtDirectionProps = ArtDirectionVariantProps & {
  className?: string;
  containerClassName?: string;
  desktop?: Media | null;
  mobile?: Media | null;
} & Omit<ImageProps, "src" | "alt">;

export const useArtDirection = (props: UseArtDirectionProps) => {
  const {
    className = "",
    containerClassName = "",
    desktop: desktopProp,
    mobile: mobileProp,
    hiddenOn = "sm",
    ...componentProps
  } = props;

  const styles = useMemo(
    () => artDirection({ hiddenOn, hasMobileMedia: !!mobileProp }),
    [hiddenOn, mobileProp]
  );

  const desktop = getMedia(desktopProp, componentProps.fill);
  const mobile = getMedia(mobileProp, componentProps.fill);
  const shouldRender = !!desktop || !!mobile;

  return {
    styles,
    containerClassName,
    className,
    shouldRender,
    desktop,
    mobile,
    componentProps,
  };
};
