import NextImage from "next/image";
import { Text } from "../../../../ui/text";
import { Spectrum } from "../../../spectrum";
import { useVoice, type UseVoiceProps } from "./use-voice";

type VoiceProps = UseVoiceProps;

const Voice = (props: VoiceProps) => {
  const { styles, track, data, setActiveTrack } = useVoice(props);
  const { name, avatar } = data;

  return (
    <div
      role="button"
      className={styles.base()}
      onClick={() => setActiveTrack(track)}
    >
      <div className={styles.caption()}>
        <Text className={styles.name()} tag="h3" weight="medium" size="label">
          {name}
        </Text>
        <Spectrum className={styles.spectrum()} track={track} />
      </div>
      <NextImage
        alt={avatar.alternativeText || name || ""}
        className={styles.avatar()}
        fill
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        src={avatar.url}
      />
    </div>
  );
};

export { Voice };
