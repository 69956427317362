"use client";

import { useEffect, useState, useMemo } from "react";
import { Vector } from "../../ui/vector";
import { copyButtonVariant } from "./code.variants";

function CopyButton({ code }: { code: string }) {
  const [copyCount, setCopyCount] = useState(0);
  const copied = copyCount > 0;

  const styles = useMemo(() => copyButtonVariant({ copied }), [copied]);

  useEffect(() => {
    if (copyCount > 0) {
      const timeout = setTimeout(() => {
        setCopyCount(0);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <button
      className={styles.base()}
      onClick={() => {
        window.navigator.clipboard
          .writeText(code)
          .then(() => {
            setCopyCount((count) => count + 1);
          })
          .catch((error) => {
            console.error(error);
          });
      }}
      type="button"
    >
      <span aria-hidden={copied} className={styles.labelCopy()}>
        <Vector className={styles.icon()} name="clipboard" />
        Copy
      </span>
      <span aria-hidden={!copied} className={styles.labelCopied()}>
        Copied!
      </span>
    </button>
  );
}

export default CopyButton;
