import { spectrumVariant } from "./spectrum.variants";
import { useShowcaseState } from "../use-showcase-state";

export type UseSpectrumProps = {
  className?: string;
  track: string;
};

const useSpectrum = (props: UseSpectrumProps) => {
  const { className = "", track } = props;

  const {
    wavesurferState: { frequencies },
    currentModule,
  } = useShowcaseState();

  const isPlaying = currentModule.track === track;
  const styles = spectrumVariant({ isPlaying });

  return {
    styles,
    className,
    isPlaying,
    frequencies: frequencies[track] || [],
  };
};

export { useSpectrum };
