import { tv } from "../../../util/tv";

export const codeVariant = tv({
  slots: {},
});

export const copyButtonVariant = tv({
  slots: {
    base: [
      "group/button",
      "absolute right-4 top-3.5",
      "overflow-hidden",
      "rounded-full",
      "py-1 pl-2 pr-3",
      "opacity-0",
      "backdrop-blur",
      "transition",
      "focus:opacity-100 group-hover:opacity-100",
      "text-display-12 font-semibold",
    ],
    labelCopy: [
      "pointer-events-none",
      "flex items-center gap-0.5",
      "text-gray-40",
      "transition duration-300",
    ],
    labelCopied: [
      "pointer-events-none",
      "absolute inset-0",
      "flex items-center justify-center",
      "transition duration-300",
      "text-emerald-400",
    ],
    icon: ["w-4 h-4", "text-gray-40", "transition-colors"],
  },
  variants: {
    copied: {
      false: {
        base: ["bg-white/5 hover:bg-white/10"],
        labelCopied: ["translate-y-1.5", "opacity-0"],
      },
      true: {
        base: ["bg-emerald-400/10", "ring-1 ring-inset ring-emerald-400/20"],
        labelCopy: ["-translate-y-1.5", "opacity-0"],
      },
    },
  },
  defaultVariants: {
    copied: false,
  },
});
