import { tv, type VariantProps } from "../../../util/tv";

export const video = tv({
  slots: {
    base: [],
    video: ["bg-black"],
  },
  variants: {
    size: {
      square: {
        base: "aspect-w-1 aspect-h-1",
      },
      video: {
        base: "aspect-w-16 aspect-h-9",
      },
      full: {
        base: "w-full h-full",
      },
      "100": {
        base: "w-100 h-100",
      },
    },
  },
  defaultVariants: {
    size: "video",
  },
});

export type VideoVariantProps = VariantProps<typeof video>;
