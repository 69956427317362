import { Text } from "../../ui/text";
import CardContentCarouselSwiper from "./card-content-carousel-swiper";
import {
  useCardContentCarousel,
  type UseCardContentCarouselProps,
} from "./use-card-content-carousel";

export type CardContentCarouselProps = UseCardContentCarouselProps;

function CardContentCarousel(props: CardContentCarouselProps) {
  const { sectionName, className, styles, hasHeader, navTheme, theme, data } =
    useCardContentCarousel(props);
  const { preTitle, title, description, cards } = data;

  if (cards.length === 0) {
    return null;
  }

  return (
    <section
      className={styles.base({ className })}
      data-section-name={sectionName}
    >
      <div className={styles.wrapper()}>
        {hasHeader ? (
          <header className={styles.heading()}>
            {!!preTitle && (
              <Text
                className={styles.preTitle()}
                size="tag"
                theme={theme}
                weight="semibold"
              >
                {preTitle}
              </Text>
            )}
            <Text
              className={styles.title()}
              size="h4"
              tag="h2"
              theme={theme}
              weight="medium"
            >
              {title}
            </Text>
            {!!description && (
              <Text
                className={styles.description()}
                size="subtitle"
                theme={theme}
                weight="medium"
              >
                {description}
              </Text>
            )}
          </header>
        ) : null}
        <div className={styles.content()}>
          <CardContentCarouselSwiper
            cardTheme={theme}
            className={styles.carousel()}
            data={data}
            navClassName={styles.nav()}
            navTheme={navTheme}
            sectionName={sectionName}
          />
        </div>
      </div>
    </section>
  );
}

export default CardContentCarousel;
