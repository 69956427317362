import { Text } from "../../../ui/text";
import { useMetadata, UseMetadataProps } from "./use-metadata";

type MetadataProps = UseMetadataProps;

const Metadata = (props: MetadataProps) => {
  const { styles, metadata } = useMetadata(props);

  return (
    <div className={styles.base()} data-module-output="metadata">
      <div className={styles.scrollArea()}>
        {metadata.map((item) => (
          <div key={item.id} className={styles.metadataGroup()}>
            <div className={styles.metadataHeading()}>
              <Text
                tag="h3"
                size="body"
                weight="bold"
                className={styles.metadataTitle()}
              >
                {item.title}
              </Text>
            </div>
            <ul className={styles.metadataList()}>
              {item.list.map((subItem) => (
                <li key={subItem}>
                  <Text
                    className={styles.metadataItem()}
                    size="tinyBody"
                    weight="semibold"
                  >
                    {subItem}
                  </Text>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Metadata };
