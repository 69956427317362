import { useEffect, useRef, useState } from "react";
import { type Lyrics } from "../../../../types/showcase/lyrics";
import { lyricsVariant } from "./lyrics.variants";
import { useShowcaseState } from "../../use-showcase-state";

export type UseLyricsProps = {
  data: Lyrics;
};

const useLyrics = (props: UseLyricsProps) => {
  const { data } = props;
  const { lyrics } = data;

  const lyricsRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const { wavesurfer, currentModule } = useShowcaseState();

  const styles = lyricsVariant();

  useEffect(() => {
    if (!wavesurfer) return;

    const audio = wavesurfer[currentModule.track];

    if (!audio) return;

    const handleTimeUpdate = () => {
      setCurrentTime(audio?.getCurrentTime() || 0);
    };

    audio.on("timeupdate", handleTimeUpdate);

    return () => {
      audio.un("timeupdate", handleTimeUpdate);
    };
  }, [wavesurfer, currentModule.track]);

  useEffect(() => {
    if (!lyricsRef.current) return;

    const lyricsEl = lyricsRef.current;
    const activeLyric = lyricsEl.querySelector<HTMLElement>(
      "[data-current-lyric=true]"
    );

    if (!activeLyric) return;

    const activeLyricTop = activeLyric.offsetTop - lyricsEl.offsetTop - 24;

    lyricsEl.scrollTo({ top: activeLyricTop, behavior: "smooth" });
  }, [currentTime]);

  return {
    styles,
    lyricsRef,
    lyrics,
    currentTime,
  };
};

export { useLyrics };
