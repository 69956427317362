"use client";

import { useAppContext } from "../../../providers/app-provider";
import { Announcement } from "../../common/announcement";

export function HeroShowcaseAnnoucement() {
  const { global } = useAppContext();

  return global?.announcement ? (
    <Announcement className="mb-8 md:mb-12" data={global.announcement} />
  ) : null;
}
