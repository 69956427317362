import { Text } from "../../../../ui/text";
import { Vector } from "../../../../ui/vector";
import { Spectrum } from "../../../spectrum";
import { useTrack, type UseTrackProps } from "./use-track";

type TrackProps = UseTrackProps;

const Track = (props: TrackProps) => {
  const { styles, data, track, setActiveTrack } = useTrack(props);
  const { icon, title } = data;

  return (
    <div
      role="button"
      className={styles.base()}
      onClick={() => setActiveTrack(track)}
    >
      <Vector name={icon} className={styles.icon()} />
      <Text className={styles.name()} tag="h3" weight="medium" size="label">
        {title}
      </Text>
      <Spectrum className={styles.spectrum()} track={track} />
    </div>
  );
};

export { Track };
