import CodeGroup from "./code-group";

function Pre({
  children,
  ...props
}: React.ComponentPropsWithoutRef<typeof CodeGroup>) {
  return <CodeGroup {...props}>{children}</CodeGroup>;
}

export default Pre;
