import { tv, type VariantProps } from "../../../util/tv";

export const iconWorkflowCardVariant = tv({
  slots: {
    base: [
      "flex flex-col gap-6 justify-between",
      "overflow-hidden",
      "rounded-3xl",
      "p-8",
      "shadow-md shadow-gray-900/5",
    ],
    icon: ["h-8 w-8"],
    content: [],
    title: ["mb-2"],
    description: [],
  },
  variants: {
    theme: {
      dark: {
        base: ["bg-white/10"],
        icon: ["text-gray-20"],
        title: ["text-gray-20"],
        description: ["text-gray-60"],
      },
      light: {
        base: ["bg-white/10"],
        icon: ["text-gray-60"],
        title: ["text-gray-60"],
        description: ["text-gray-60"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type IconWorkflowCardVariantProps = VariantProps<
  typeof iconWorkflowCardVariant
>;

export type IconWorkflowCardReturnVariantProps = ReturnType<
  typeof iconWorkflowCardVariant
>;
