import { Button } from "../../ui/button";
import { useSwiperNav, type UseSwiperNavProps } from "./use-swiper-nav";

export type SwiperNavProps = UseSwiperNavProps;

function SwiperNav(props: SwiperNavProps) {
  const {
    id,
    className,
    styles,
    prevClassName,
    nextClassName,
    buttonColor,
    buttonSize,
  } = useSwiperNav(props);

  return (
    <div
      className={styles.base({ className })}
      data-component-name="swiper-nav"
    >
      <Button
        appearance="solid"
        className={styles.button({
          className: [`prev-carousel-${id}`, prevClassName],
        })}
        color={buttonColor}
        size={buttonSize}
        startIcon="arrow-left"
        type="button"
        rounded
      />
      <Button
        appearance="solid"
        className={styles.button({
          className: [`next-carousel-${id}`, nextClassName],
        })}
        color={buttonColor}
        size={buttonSize}
        startIcon="arrow-right"
        type="button"
        rounded
      />
    </div>
  );
}

export default SwiperNav;
