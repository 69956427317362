"use client";

import useInfiniteScroll from "../../../hooks/use-infinite-scroll";
import type { ModelsMediaCard, Theme } from "../../../types";
import { MediaCard } from "../../common/media-card";
import { Button } from "../../ui/button";
import { blogListGridVariant } from "./blog-list.variants";

export interface BlogListGridProps {
  theme: Theme;
  moreButtonLabel: string;
  sectionName: string;
  cards: ModelsMediaCard[];
}

function BlogListGrid({
  theme,
  sectionName,
  moreButtonLabel,
  cards,
}: BlogListGridProps) {
  const styles = blogListGridVariant();
  const items = useInfiniteScroll<ModelsMediaCard>(cards, 6);

  const { loading, hasMoreItems, loadedItems, getMoreItems } = items;

  const [highlight, ...posts] = loadedItems;

  return (
    <div className={styles.base()}>
      <div className={styles.content()}>
        <div className={styles.highlight()}>
          {highlight ? (
            <MediaCard
              data={highlight}
              layout="horizontal"
              sectionName={sectionName}
              theme={theme}
            />
          ) : null}
        </div>
        {posts.map((card) => (
          <div className={styles.card()} key={`media-card-${card.id}`}>
            <MediaCard data={card} sectionName={sectionName} theme={theme} />
          </div>
        ))}
      </div>
      {hasMoreItems ? (
        <footer className={styles.footing()}>
          <Button
            appearance="solid"
            color="blue"
            isLoading={loading}
            loadingLabel="Loading"
            onClick={() => {
              getMoreItems();
            }}
          >
            {moreButtonLabel}
          </Button>
        </footer>
      ) : null}
    </div>
  );
}

export default BlogListGrid;
