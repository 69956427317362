"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import type { SectionsCarouselMedia, Theme } from "../../../types";
import { SwiperNav } from "../../util/swiper-nav";
import { MediaCard } from "../../common/media-card";

export interface CarouselMediaSwiperProps {
  className?: string;
  navClassName?: string;
  sectionName: string;
  data: SectionsCarouselMedia;
  navTheme: Theme;
  hasMoreThreeCards: boolean;
}

function CarouselMediaSwiper({
  className,
  navClassName,
  sectionName,
  navTheme,
  data,
  hasMoreThreeCards,
}: CarouselMediaSwiperProps) {
  const { id, theme, cards } = data;

  return (
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
      className={className}
      initialSlide={0}
      modules={[Navigation]}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`,
      }}
      simulateTouch={false}
      slidesPerView={3}
      spaceBetween={40}
    >
      {cards.map((card) => (
        <SwiperSlide className="!h-auto" key={`icon-card-${card.id}`}>
          <MediaCard
            data={card}
            key={`card-content-${card.id}`}
            sectionName={sectionName}
            theme={theme}
          />
        </SwiperSlide>
      ))}
      {hasMoreThreeCards ? (
        <SwiperNav className={navClassName} id={id} theme={navTheme} />
      ) : null}
    </Swiper>
  );
}

export default CarouselMediaSwiper;
