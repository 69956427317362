import { Voice } from "./voice/voice";
import { useVoices, UseVoicesProps } from "./use-voices";

type VoicesProps = UseVoicesProps;

const Voices = (props: VoicesProps) => {
  const { styles, moduleId, tracks } = useVoices(props);

  return (
    <div className={styles.base()} data-module-output="voices">
      <div className={styles.scrollArea()}>
        {tracks.map(({ ...track }, i) => (
          <Voice key={track.id} track={`${moduleId}-${i}`} data={track} />
        ))}
      </div>
    </div>
  );
};

export { Voices };
