import { useShowcaseState } from "./use-showcase-state";
import { showcaseOutputVariant } from "./showcase-output.variants";

export const useShowcaseOutput = () => {
  const { currentModule, data } = useShowcaseState();

  const styles = showcaseOutputVariant();

  return {
    styles,
    currentModule,
    data,
  };
};
