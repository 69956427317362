import { useState } from "react";
import { dispatchGoogleEvent } from "../../../util/google";
import { EventAction } from "../../../config/events";
import { extractYouTubeId } from "../../../util/youtube";
import type { ComponentProps, Media } from "../../../types";
import { videoCard } from "./video-card.variants";

export type UseVideoCardProps = ComponentProps<
  {
    title: string;
    cover: Media | null;
    url: string;
  },
  { priority?: boolean }
>;

export const useVideoCard = (props: UseVideoCardProps) => {
  const { className = "", data, priority = false } = props;

  const [modalState, setModalState] = useState(false);

  const videoId = extractYouTubeId(data.url);
  const poster = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

  const styles = videoCard();

  const handleClick = () => {
    dispatchGoogleEvent(EventAction.WebsitePlay, `youtube_${videoId}`);
    setModalState(true);
  };

  return {
    styles,
    className,
    videoId,
    poster,
    priority,
    data,
    modalState,
    setModalState,
    handleClick,
  };
};
