import { useCallback, useContext, useMemo } from "react";
import { ShowcaseContext } from "./showcase-context";

export const useShowcaseState = () => {
  const {
    containerRefs,
    state,
    setState,
    wavesurfer,
    setWavesurfer,
    wavesurferState,
    setWavesurferState,
  } = useContext(ShowcaseContext);

  const setActiveModule = useCallback(
    (moduleId: string) => {
      setState((prevState) => {
        return {
          ...prevState,
          current: prevState.current.map((current) => ({
            ...current,
            isActive: current.id === moduleId,
          })),
        };
      });
    },
    [state.data]
  );

  const setActiveTrack = useCallback(
    (track: string) => {
      setState((prevState) => {
        const currentModule = prevState.current.find(
          (current) => current.isActive
        );

        if (!currentModule) return prevState;

        return {
          ...prevState,
          current: prevState.current.map((current) =>
            current.id === currentModule.id ? { ...current, track } : current
          ),
        };
      });
    },
    [state.data]
  );

  const currentModule = useMemo(() => {
    return state.current.find((current) => current.isActive)!;
  }, [state.current]);

  const setWavesurferStatus = useCallback(
    (
      type: "isReady" | "isPlaying" | "currentProgress",
      value: boolean | number
    ) => {
      setWavesurferState((prevState) => ({
        ...prevState,
        [type]: value,
      }));
    },
    [state.data]
  );

  const setWavesurferFrequency = useCallback(
    (track: string, frequencies: number[]) => {
      setWavesurferState((prevState) => ({
        ...prevState,
        frequencies: {
          ...prevState.frequencies,
          [track]: frequencies,
        },
      }));
    },
    [state.data]
  );

  return {
    ...state,
    containerRefs,
    currentModule,
    setActiveModule,
    setActiveTrack,
    wavesurfer,
    setWavesurfer,
    wavesurferState,
    setWavesurferStatus,
    setWavesurferFrequency,
  };
};
