import type { ButtonVariantProps } from "../../ui/button/button.variants";
import {
  swiperNavVariant,
  type SwiperNavVariantProps,
} from "./swiper-nav.variants";

export type UseSwiperNavProps = {
  id: string | number;
  className?: string;
  prevClassName?: string;
  nextClassName?: string;
  buttonColor?: ButtonVariantProps["color"];
  buttonSize?: ButtonVariantProps["size"];
} & SwiperNavVariantProps;

export const useSwiperNav = (props: UseSwiperNavProps) => {
  const {
    id,
    className = "",
    prevClassName = "",
    nextClassName = "",
    buttonColor = "gray",
    buttonSize = "md",
    theme = "dark",
  } = props;

  const styles = swiperNavVariant({ theme });

  const btnColor: ButtonVariantProps["color"] =
    buttonColor || (theme === "dark" ? "gray" : "white");

  return {
    id,
    styles,
    className,
    prevClassName,
    nextClassName,
    buttonSize,
    buttonColor: btnColor,
  };
};
