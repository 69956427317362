import NextImage from "next/image";
import { useAvatar, type UseAvatarProps } from "./use-avatar";

export type AvatarProps = UseAvatarProps;

function Avatar(props: AvatarProps) {
  const { styles, className, image } = useAvatar(props);

  return (
    <div className={styles.base({ className })}>
      <NextImage
        alt={image.alternativeText || "avatar"}
        className={styles.image()}
        height={image.height}
        src={image.url}
        width={image.width}
      />
    </div>
  );
}

export default Avatar;
