import { useSwiperSlide } from "swiper/react";
import { ShowcaseModule } from "../../../types/showcase";
import type { ComponentProps, Theme } from "../../../types";
import { showcaseCardVariant } from "./showcase-card.variants";

export type UseShowcaseCardProps = ComponentProps<
  ShowcaseModule,
  { theme: Theme }
>;

const getCardState = (swiperSlide: ReturnType<typeof useSwiperSlide>) => {
  if (swiperSlide.isActive) return "active";
  if (swiperSlide.isNext) return "next";
  if (swiperSlide.isPrev) return "prev";
  if (swiperSlide.isVisible) return "visible";

  return "notVisible";
};

export const useShowcaseCard = (props: UseShowcaseCardProps) => {
  const { theme = "dark", data } = props;

  const swiperSlide = useSwiperSlide();
  const cardState = getCardState(swiperSlide);

  const styles = showcaseCardVariant({ theme, state: cardState });

  return {
    theme,
    styles,
    data,
  };
};
