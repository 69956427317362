import { Text } from "../../../../ui/text";
import { useLyric, type UseLyricProps } from "./use-lyric";

type LyricProps = UseLyricProps;

const Lyric = (props: LyricProps) => {
  const { styles, children, isCurrentLyric } = useLyric(props);

  return (
    <Text
      className={styles.base()}
      tag="span"
      weight="semibold"
      size="h6"
      data-current-lyric={isCurrentLyric}
    >
      {children}
    </Text>
  );
};

export { Lyric };
