import type {
  SectionProps,
  SectionsCardContentCarousel,
  Theme,
} from "../../../types";
import { cardContentCarouselVariant } from "./card-content-carousel.variants";

export type UseCardContentCarouselProps =
  SectionProps<SectionsCardContentCarousel>;

export const useCardContentCarousel = (props: UseCardContentCarouselProps) => {
  const { className = "", sectionName, data } = props;
  const { title, description } = data;
  const theme: Theme = data.theme === "gray" ? "dark" : data.theme;
  const navTheme: Theme = theme === "dark" ? "light" : "dark";

  const styles = cardContentCarouselVariant({ theme: data.theme });
  const hasHeader = !!title || !!description;

  return {
    sectionName,
    className,
    styles,
    hasHeader,
    navTheme,
    theme,
    data,
  };
};
