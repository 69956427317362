import { useMemo, useRef } from "react";
import { useClickAway, useKeyPressEvent } from "react-use";
import useDisableScroll from "../../../hooks/use-disable-scroll";
import { modal, type ModalVariantProps } from "./modal.variants";

export type UseModalProps = ModalVariantProps & {
  className?: string;
  children?: React.ReactNode;
  active?: boolean;
  handleClose: () => void;
  hideCloseButton?: boolean;
};

export const useModal = (props: UseModalProps) => {
  const {
    className = "",
    children,
    centered = true,
    size = "xl",
    active = false,
    handleClose = () => {},
    hideCloseButton = false,
  } = props;

  const modalRef = useRef(null);

  const styles = useMemo(() => modal({ centered, size }), [centered, size]);

  useDisableScroll(active, modalRef);
  useClickAway(modalRef, handleClose);
  useKeyPressEvent("Escape", handleClose);

  return {
    modalRef,
    className,
    children,
    handleClose,
    hideCloseButton,
    active,
    styles,
  };
};
