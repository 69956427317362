"use client";

import { AnimationControls, LazyMotion, domAnimation, m } from "motion/react";
import TickerClone from "./ticker-clone";
import { useTicker, type UseTickerProps } from "./use-ticker";

export type TickerProps = UseTickerProps;

function Ticker(props: TickerProps) {
  const {
    styles,
    className,
    wrapperRef,
    itemsRef,
    getAnimation,
    fakeItems,
    isVisible,
    drawDelay,
    spaceBetween,
    getScrollAreaStyle,
    children,
  } = useTicker(props);

  return (
    <LazyMotion features={domAnimation}>
      <div className={styles.base({ className })} ref={wrapperRef}>
        <m.div
          animate={getAnimation()}
          className={styles.scrollArea({ className: spaceBetween })}
          style={getScrollAreaStyle()}
        >
          {isVisible ? (
            <>
              <TickerClone
                className={spaceBetween}
                drawDelay={drawDelay}
                fakeItems={fakeItems}
                styles={styles}
              >
                {children}
              </TickerClone>
              <div
                className={styles.group({ className: spaceBetween })}
                ref={itemsRef}
              >
                {children.map((item, index) => (
                  <div
                    className={styles.item()}
                    key={index}
                    style={{ animationDelay: drawDelay() }}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <TickerClone
                className={spaceBetween}
                drawDelay={drawDelay}
                fakeItems={fakeItems}
                styles={styles}
              >
                {children}
              </TickerClone>
            </>
          ) : null}
        </m.div>
      </div>
    </LazyMotion>
  );
}

export default Ticker;
