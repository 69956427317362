"use client";

import { CardContentCarousel } from "../card-content-carousel";
import DownloadsModal from "./downloads-modal";
import { useDownloads, type UseDownloadsProps } from "./use-downloads";

export type DownloadsProps = UseDownloadsProps;

function Downloads(props: DownloadsProps) {
  const {
    sectionName,
    className,
    modalState,
    setModalState,
    currentFile,
    data,
  } = useDownloads(props);

  return (
    <>
      <CardContentCarousel
        className={className}
        data={data}
        sectionName={sectionName}
      />
      <DownloadsModal
        currentFile={currentFile}
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  );
}

export default Downloads;
