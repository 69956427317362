import { type Tracks } from "../../../../types/showcase/track";
import { tracksVariant } from "./tracks.variants";

export type UseTracksProps = {
  data: Tracks;
};

const useTracks = (props: UseTracksProps) => {
  const { data } = props;
  const { id, tracks } = data;

  const styles = tracksVariant({
    gridSize: Math.min(Math.max(tracks.length, 1), 5) as 1 | 2 | 3 | 4 | 5,
  });

  return {
    styles,
    moduleId: id,
    tracks,
  };
};

export { useTracks };
