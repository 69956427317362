import { tv, type VariantProps } from "../../../util/tv";

const spectrumVariant = tv({
  slots: {
    base: ["flex justify-center items-center gap-0.5", "w-20 h-3"],
    bar: [
      "w-0.5",
      "shrink-0",
      "relative",
      "flex my-auto",
      "rounded-full",
      "mt-auto",
    ],
  },
  variants: {
    isPlaying: {
      false: {
        bar: ["bg-white/20"],
      },
      true: {
        bar: ["bg-blue-300", "shadow-showcase"],
      },
    },
  },
  defaultVariants: {
    isPlaying: false,
  },
});

export type SpectrumVariantProps = VariantProps<typeof spectrumVariant>;

export { spectrumVariant };
