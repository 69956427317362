import { tv, type VariantProps } from "../../util/tv";

export const showcaseVariant = tv({
  slots: {
    base: ["relative z-10", "flex flex-col items-center justify-center"],
    wrapper: ["flex flex-col items-center justify-center"],
    separator: [
      "relative z-20",
      "block",
      "w-[1px] h-8",
      "bg-gray-500",
      "before:content-['']",
      "before:block",
      "before:w-[11px] before:h-[11px]",
      "before:rounded-full",
      "before:bg-black",
      "before:border before:border-gray-500",
      "before:absolute",
      "before:-top-[6px] before:-left-[5px] before-right-[5px]",
      "before:m-auto",
      "after:content-['']",
      "after:block",
      "after:w-[11px] after:h-[11px]",
      "after:rounded-full",
      "after:bg-black",
      "after:border after:border-gray-500",
      "after:absolute",
      "after:-bottom-[6px] after:-left-[5px] after-right-[5px]",
      "after:m-auto",
    ],
  },
});

export type ShowcaseVariantProps = VariantProps<typeof showcaseVariant>;

export type ShowcaseReturnVariantProps = ReturnType<typeof showcaseVariant>;
