type WorkflowDemoIframeProps = {
  demoUrl: string;
  className: string;
  title: string | null;
};

function WorkflowDemoIframe({
  demoUrl,
  className,
  title,
}: WorkflowDemoIframeProps) {
  return (
    <iframe
      className={className}
      height="100%"
      src={demoUrl}
      title={title || undefined}
      width="100%"
    >
      Browser not compatible.
    </iframe>
  );
}

export default WorkflowDemoIframe;
