"use client";

import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { RichContentCard } from "../../../types";
import { Video } from "../video";
import { RichContentCardVariantProps } from "./rich-content-card.variants";

export type RichContentCardVideoProps = { className?: string } & Pick<
  RichContentCard,
  "backgroundSize" | "background"
> &
  Pick<RichContentCardVariantProps, "type">;

const RichContentCardVideo = ({
  className = "",
  type,
  background,
  backgroundSize,
}: RichContentCardVideoProps) => {
  const [showVideo, setShowVideo] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= 768 && type === "video") {
      !showVideo && setShowVideo(true);
    } else {
      showVideo && setShowVideo(false);
    }
  }, [width, showVideo, type]);

  if (type !== "video" || !showVideo || !background) return null;

  return (
    <Video
      size={backgroundSize === "contain" ? "video" : "full"}
      className={className}
      src={background.url}
      mime={background.mime}
    />
  );
};

export default RichContentCardVideo;
