"use client";

import NextLink from "next/link";
import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import { useModuleCard, type UseModuleCardProps } from "./use-module-card";

export type ModuleCardProps = UseModuleCardProps;

function ModuleCard(props: ModuleCardProps) {
  const { className, styles, theme, data } = useModuleCard(props);
  const { title, description, url } = data;

  return (
    <article
      className={styles.base({ className })}
      data-component-name="module-card"
    >
      <NextLink className={styles.link()} href={url} title={title}>
        <Text
          className={styles.title()}
          size="subtitle"
          tag="h3"
          theme={theme}
          weight="semibold"
        >
          {title}
        </Text>
        <Text className={styles.description()} theme={theme} weight="medium">
          {description}
        </Text>
        <Text
          className={styles.cta()}
          size="label"
          tag="span"
          weight="semibold"
        >
          Details
          <Vector className={styles.arrow()} name="arrow-right" />
        </Text>
      </NextLink>
    </article>
  );
}

export default ModuleCard;
