import { tv, type VariantProps } from "../../../util/tv";

export const hubspotFormVariant = tv({
  slots: {
    base: [
      "flex justify-center items-center",
      "rounded-xl",
      "w-full max-w-xl",
      "min-h-[200px]",
    ],
    form: ["w-full"],
  },
});

export type HubspotFormVariantProps = VariantProps<typeof hubspotFormVariant>;
