import { tv, type VariantProps } from "../../../../util/tv";

const lyricsVariant = tv({
  slots: {
    base: ["w-full h-full", "pr-2.5"],
    scrollArea: [
      "w-full h-full",
      "overflow-y-auto",
      "tiny-scrollbar-showcase tiny-scrollbar-showcase--vertical",
      "p-6 pr-2.5",
    ],
    line: ["flex flex-wrap", "[&:not(:last-child)]:mb-1.5"],
  },
});

export type LyricsVariantProps = VariantProps<typeof lyricsVariant>;

export { lyricsVariant };
