"use client";

import type { TickerReturnVariantProps } from "./ticker.variants";

interface TickerCloneProps {
  styles: TickerReturnVariantProps;
  className?: string;
  fakeItems: number;
  children: React.ReactNode[];
  drawDelay: () => string | undefined;
}

function TickerClone({
  styles,
  className = "",
  fakeItems,
  children = [],
  drawDelay,
}: TickerCloneProps) {
  return (
    <div className={styles.group({ className })}>
      {[...Array(fakeItems)].map((_) =>
        children.map((item, index) => (
          <div
            className={styles.item()}
            key={index}
            style={{ animationDelay: drawDelay() }}
          >
            {item}
          </div>
        ))
      )}
    </div>
  );
}

export default TickerClone;
