import { tv, type VariantProps } from "../../../util/tv";

export const iconCardVariant = tv({
  slots: {
    base: ["flex flex-col", "w-full h-full"],
    header: ["h-full", "rounded-t-2xl"],
    icon: ["flex", "mb-4", "h-8 w-8"],
    title: [],
    description: ["mt-3"],
    footer: ["rounded-b-2xl"],
  },
  variants: {
    theme: {
      dark: {
        base: "text-white",
        icon: "text-blue-400",
        description: "text-gray-60",
      },
      light: {
        base: "text-black",
        icon: "text-blue-100",
        description: "text-gray-60",
      },
    },
    appearance: {
      ghost: {
        base: "",
        footer: "mt-6",
      },
      card: {
        base: "drop-shadow-md rounded-2xl",
        header: "p-6",
        footer: "p-6",
      },
    },
    hasFooting: {
      false: {
        header: "rounded-b-2xl",
      },
      true: {},
    },
  },
  compoundVariants: [
    {
      theme: "dark",
      appearance: "card",
      className: {
        header: "bg-gray-800",
        footer: "bg-gray-600",
      },
    },
    {
      theme: "light",
      appearance: "card",
      className: {
        header: "bg-white",
        footer: "bg-gray-1",
      },
    },
  ],
  defaultVariants: {
    theme: "dark",
    appearance: "ghost",
  },
});

export type IconCardVariantProps = VariantProps<typeof iconCardVariant>;

export type IconCardReturnVariantProps = ReturnType<typeof iconCardVariant>;
