import { tv, type VariantProps } from "../../../util/tv";

export const galleryCardVariant = tv({
  slots: {
    base: ["aspect-h-12 aspect-w-16", "overflow-hidden", "rounded-3xl"],
    image: [
      "h-full w-full",
      "overflow-hidden",
      "rounded-3xl",
      "object-cover object-center",
    ],
  },
});

export type GalleryCardVariantProps = VariantProps<typeof galleryCardVariant>;
