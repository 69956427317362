import { tv, type VariantProps } from "../../../../../util/tv";

const beatVariant = tv({
  slots: {
    base: [
      "flex justify-center items-center",
      "h-12",
      "font-noto",
      "rounded-md",
      "transition-all duration-200 ease-in-out",
      "bg-gray-800 text-white",
      "select-none",
    ],
    chord: ["text-center text-[13px] font-bold"],
    type: ["text-[10px]", "align-top"],
  },
  variants: {
    isOriginal: {
      false: {
        base: ["text-white/40"],
      },
      true: {},
    },
    isActiveBeat: {
      false: {},
      true: {
        base: ["!bg-white !text-gray-900"],
      },
    },
    isActiveCompass: {
      false: {
        base: [],
      },
      true: {
        base: ["bg-gray-400 text-white"],
      },
    },
  },
  defaultVariants: {
    isActiveBeat: false,
    isActiveChord: false,
    isActiveCompass: false,
  },
});

export type BeatVariantProps = VariantProps<typeof beatVariant>;

export { beatVariant };
