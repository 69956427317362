import { Chord } from "../../../../../types/showcase/chords-and-sections";
import { beatVariant } from "./beat.variants";

export type UseBeatProps = {
  chord: Chord | null;
  isOriginal: boolean;
  isActiveCompass: boolean;
  isActiveBeat: boolean;
};

const useBeat = (props: UseBeatProps) => {
  const { chord, isOriginal, isActiveCompass, isActiveBeat } = props;

  const styles = beatVariant({
    isOriginal,
    isActiveCompass,
    isActiveBeat,
  });

  const splitedChord = chord?.chord_simple_pop.split(/(\d+)/);

  const note = splitedChord?.[0] ?? null;
  const type = splitedChord?.[1] ?? null;
  const bass = chord?.bass ?? null;

  return {
    styles,
    note,
    type,
    bass,
    isActiveBeat,
  };
};

export { useBeat };
