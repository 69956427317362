"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import type { ModelsReviewCard } from "../../../types";
import { QuoteCard } from "../../common/quote-card";
import { CarouselButton } from "../../common/carousel-button";

export interface SocialProofCarouselSwiperProps {
  swiperClassName?: string;
  navClassName?: string;
  testimonials: ModelsReviewCard[];
}

function SocialProofCarouselSwiper({
  swiperClassName,
  navClassName,
  testimonials,
}: SocialProofCarouselSwiperProps) {
  const swiperId = "social-proof-testimonials";

  return (
    <>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          720: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
        }}
        modules={[Navigation]}
        navigation={{
          prevEl: `.prev-carousel-${swiperId}`,
          nextEl: `.next-carousel-${swiperId}`,
        }}
        simulateTouch={false}
        slidesPerView={3}
        spaceBetween={32}
        className={swiperClassName}
        loop={true}
      >
        {testimonials.map((card) => (
          <SwiperSlide className="!h-auto" key={`review-card-${card.id}`}>
            <QuoteCard data={card} theme={"dark"} />
          </SwiperSlide>
        ))}
      </Swiper>
      <nav className={navClassName} id={swiperId}>
        <CarouselButton
          className={`prev-carousel-${swiperId} ml-[-36px]`}
          startIcon="arrow-left"
        />
        <CarouselButton
          className={`next-carousel-${swiperId} mr-[-36px]`}
          startIcon="arrow-right"
        />
      </nav>
    </>
  );
}

export default SocialProofCarouselSwiper;
