"use client";

import { Children, isValidElement } from "react";
import { Navigation, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperNav } from "../../util/swiper-nav";
import { galleryVariant } from "./gallery.variants";

export interface GalleryProps {
  children?: React.ReactNode;
}

function Gallery({ children }: GalleryProps) {
  const id = "gallery";
  const styles = galleryVariant();

  const renderGallery = () => {
    return Children.map(children, (child, i) => {
      if (!isValidElement(child)) {
        return null;
      }

      return (
        <SwiperSlide key={`gallery-item-${i}`}>
          <figure className={styles.figure()}>{child}</figure>
        </SwiperSlide>
      );
    });
  };

  return (
    <Swiper
      className={styles.base()}
      effect="fade"
      modules={[Navigation, EffectFade]}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`,
      }}
      simulateTouch={false}
      slidesPerView={1}
    >
      {renderGallery()}
      <SwiperNav className={styles.nav()} id={id} theme="light" />
    </Swiper>
  );
}

export default Gallery;
