import { forwardRef, type Ref } from "react";
import { useSelect, type UseSelectProps } from "./use-select";

export type SelectProps = UseSelectProps;

function Select(props: SelectProps, ref: Ref<HTMLSelectElement>) {
  const { styles, className, fieldId, label, options, componentProps } =
    useSelect({ ...props, ref });

  const getOptions = () => {
    const opts = options.map((option) => {
      if (typeof option === "object") {
        return (
          <option
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        );
      }

      return (
        <option key={option} value={option}>
          {option}
        </option>
      );
    });

    if (componentProps.placeholder) {
      opts.unshift(
        <option key="placeholder" value="">
          {componentProps.placeholder}
        </option>
      );
    }

    return opts;
  };

  return (
    <div className={styles.base({ className })}>
      <label className={styles.label()} htmlFor={fieldId}>
        {label}
      </label>
      <div className={styles.group()}>
        <select className={styles.input()} id={fieldId} {...componentProps}>
          {getOptions()}
        </select>
      </div>
    </div>
  );
}

export default forwardRef(Select);
