import { useState } from "react";

export const useOutputModal = () => {
  const [modalActive, setModalActive] = useState(false);

  const handleShowModal = () => {
    setModalActive(true);
  };

  const handleCloseModal = () => {
    setModalActive(false);
  };

  return {
    modalActive,
    handleShowModal,
    handleCloseModal,
  };
};
