/* eslint-disable react-hooks/exhaustive-deps --- prevent rerendered */
import { useEffect, useState } from "react";
import { useHubspotContext } from "../providers/hubspot-provider";

interface UseHubspotFormProps {
  region: string;
  portalId: string;
  formId: string;
  target: string;
}

const useHubspotForm = (formProps: UseHubspotFormProps) => {
  const { loaded, error } = useHubspotContext();
  const [formCreated, setFormCreated] = useState(false);

  useEffect(() => {
    if (loaded && !formCreated) {
      try {
        window.hbspt.forms.create(formProps);
        setFormCreated(true);
      } catch (e) {
        console.error("Failed to create hubspot form.", { error: e });
        setFormCreated(false);
      }
    }
  }, [loaded, formCreated, setFormCreated]);

  return {
    loaded,
    formCreated,
    error,
  };
};

export default useHubspotForm;
