"use client";

import { useCallback } from "react";
import { Button } from "../../ui/button";
import { Modal } from "../../util/modal";
import { WorkflowDemoIframe } from "../../common/workflow-demo-iframe";
import { useWorkflowTabModal } from "./use-workflow-tab-modal";
import { type UseWorkflowTabModalProps } from "./use-workflow-tab-modal";

type WorkflowTabModalProps = UseWorkflowTabModalProps;

export function WorkflowTabModal(props: WorkflowTabModalProps) {
  const {
    styles,
    button,
    jobId,
    modalActive,
    handleShowModal,
    handleCloseModal,
  } = useWorkflowTabModal(props);

  const demoUrl = `https://music.ai/dash/demos/workflow-preview/${jobId}`;

  const Iframe = useCallback(
    () => (
      <WorkflowDemoIframe
        className={styles.frame()}
        demoUrl={demoUrl}
        title={"Try Workflow"}
      />
    ),
    []
  );

  return (
    <div className={styles.buttonsWrapper()}>
      {!!button && (
        <Button
          {...button}
          href={button.url}
          id={`${button.id}`}
          className={styles.cta()}
        >
          {button.text}
        </Button>
      )}
      <Button
        appearance="link"
        color="white"
        weight="medium"
        title={"Preview"}
        className={styles.cta()}
        onClick={handleShowModal}
      >
        Preview
      </Button>
      <Modal active={modalActive} handleClose={handleCloseModal}>
        <div className="aspect-w-16 aspect-h-9">
          <Iframe />
        </div>
      </Modal>
    </div>
  );
}
