import { useMemo } from "react";
import { type Theme } from "../../types";
import { type ShowcaseData } from "../../types/showcase";
import { useWavesurfer } from "./use-wavesurfer";
import { useShowcaseState } from "./use-showcase-state";
import { showcasePlayerVariant } from "./showcase-player.variants";

export type UseShowcasePlayerProps = {
  theme: Theme;
  cta: ShowcaseData["cta"];
};

const waveSurferOptions = {
  waveColor: "#474747",
  progressColor: "#ffffff",
  cursorWidth: 0,
  barWidth: 1,
  barGap: 2,
  height: 40,
  dragToSeek: true,
  normalize: true,
};

export const useShowcasePlayer = (props: UseShowcasePlayerProps) => {
  const { theme = "dark", cta } = props;
  const { containerRefs, data } = useShowcaseState();

  const tracks = useMemo(() => {
    if (!data) return {};

    const { input: original, modules } = data;

    let currentTracks: Record<string, string> = {};

    modules.forEach((item) => {
      if ("tracks" in item) {
        item.tracks.forEach(({ url }, trackIndex) => {
          currentTracks[`${item.id}-${trackIndex}`] = url;
        });
      } else {
        currentTracks[`${item.id}-0`] = original;
      }
    });

    return currentTracks;
  }, [data]);

  const wavesurfer = useWavesurfer({
    containerRefs,
    tracks,
    ...waveSurferOptions,
  });

  const styles = useMemo(
    () =>
      showcasePlayerVariant({
        theme,
        isReady: wavesurfer.isReady,
      }),
    [theme, wavesurfer.isReady]
  );

  return {
    styles,
    theme,
    containerRefs,
    tracks,
    wavesurfer,
    cta,
  };
};
