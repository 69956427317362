/* eslint-disable no-param-reassign -- disable */
import { Children, isValidElement } from "react";
import CodePanelHeading from "./code-panel-heading";
import CopyButton from "./copy-button";

function CodePanel({
  children,
  label,
  code,
}: {
  children: React.ReactNode;
  label?: string;
  code?: string;
}) {
  const child = Children.only(children);

  if (isValidElement(child)) {
    label = child.props.label ?? label;
    code = child.props.code ?? code;
  }

  if (!code) {
    throw new Error(
      "`CodePanel` requires a `code` prop, or a child with a `code` prop."
    );
  }

  return (
    <div className="group bg-white/5">
      <CodePanelHeading label={label} />
      <div className="relative">
        <pre className="overflow-x-auto p-4 text-xs text-white">{children}</pre>
        <CopyButton code={code} />
      </div>
    </div>
  );
}

export default CodePanel;
