"use client";

import type { Dispatch, SetStateAction } from "react";
import { DownloadForm } from "../../forms/download-form";
import { Modal } from "../../util/modal";
import type { ModelsDownloadCard } from "../../../types";

interface DownloadsModalProps {
  currentFile?: ModelsDownloadCard;
  modalState: boolean;
  setModalState: Dispatch<SetStateAction<boolean>>;
}

function DownloadsModal({
  currentFile,
  modalState,
  setModalState,
}: DownloadsModalProps) {
  return (
    <Modal
      active={modalState}
      handleClose={() => {
        setModalState(false);
      }}
      hideCloseButton
      size="md"
    >
      <DownloadForm currentFile={currentFile} />
    </Modal>
  );
}

export default DownloadsModal;
