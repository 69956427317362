import type { Media } from "../../../types";
import { galleryCardVariant } from "./gallery-card.variants";

export interface UseGalleryCardProps {
  className?: string;
  image: Media;
}

export const useGalleryCard = (props: UseGalleryCardProps) => {
  const { className = "", image } = props;

  const styles = galleryCardVariant();

  return {
    styles,
    className,
    image,
  };
};
