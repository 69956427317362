import { tv, type VariantProps } from "../../../util/tv";

export const inputVariant = tv({
  slots: {
    base: ["w-full"],
    label: ["block", "text-display-14 font-medium"],
    group: ["w-full", "mt-2"],
    input: [
      "block",
      "w-full",
      "rounded-xl",
      "border-0",
      "px-4 py-3",
      "sm:text-display-16",
      "shadow-sm",
      "ring-1 ring-inset",
      "focus:ring-2 focus:ring-inset",
    ],
  },
  variants: {
    theme: {
      dark: {
        label: ["text-gray-1"],
        input: [
          "bg-black",
          "text-gray-1",
          "focus:ring-blue-400",
          "ring-white/20",
          "placeholder:text-gray-60",
        ],
      },
      light: {
        label: ["text-gray-800"],
        input: [
          "text-gray-800",
          "focus:ring-blue-100",
          "ring-black/20",
          "placeholder:text-gray-400",
        ],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type InputVariantProps = VariantProps<typeof inputVariant>;

export type InputReturnVariantProps = ReturnType<typeof inputVariant>;
