import { tv, type VariantProps } from "../../../../../util/tv";

const voiceVariant = tv({
  slots: {
    base: [
      "relative",
      "shrink-0",
      "overflow-hidden",
      "first-of-type:rounded-l-xl",
      "last-of-type:rounded-r-xl",
      "group",
    ],
    caption: [
      "flex flex-col items-center justify-end",
      "w-full h-full",
      "px-8 py-4",
      "text-center",
      "bg-gradient-radial-2",
    ],
    name: ["mb-3"],
    spectrum: [],
    avatar: [
      "absolute -z-10",
      "inset-0",
      "object-cover",
      "object-center",
      "transition-all duration-500 ease-in-out",
      "group-hover:grayscale-0",
    ],
  },
  variants: {
    isActive: {
      true: {
        avatar: ["grayscale-0"],
      },
      false: {
        avatar: ["grayscale"],
      },
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export type VoiceVariantProps = VariantProps<typeof voiceVariant>;

export { voiceVariant };
