import { tv, type VariantProps } from "../../../util/tv";

export const avatarVariant = tv({
  slots: {
    base: ["shrink-0", "rounded-full"],
    image: ["w-full h-full", "rounded-full"],
  },
  variants: {
    size: {
      xs: {
        base: ["w-3 h-3"],
      },
      sm: {
        base: ["w-4 h-4"],
      },
      md: {
        base: ["w-8 h-8"],
      },
      lg: {
        base: ["w-12 h-12"],
      },
      xl: {
        base: ["w-16 h-16"],
      },
    },
  },
});

export type AvatarVariantProps = VariantProps<typeof avatarVariant>;

export type AvatarReturnVariantProps = ReturnType<typeof avatarVariant>;
