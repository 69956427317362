import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useLocalStorage } from "react-use";
import type { DownloadFields } from "../../../types/forms";
import { getDownloadLink } from "../../../services/datasets";
import type { ModelsDownloadCard, Theme } from "../../../types";
import { downloadFormVariant } from "./download-form.variants";

export interface UseDownloadFormProps {
  className?: string;
  currentFile?: ModelsDownloadCard;
}

type DatasetFormData = Pick<DownloadFields, "full_name" | "email" | "org">;

export const useDownloadForm = (props: UseDownloadFormProps) => {
  const { className = "", currentFile } = props;

  const [formData, setFormData] = useLocalStorage<DatasetFormData>(
    "DATASET_DATA",
    undefined
  );

  const form = useForm<DownloadFields>({
    defaultValues: formData,
  });
  const styles = downloadFormVariant();
  const theme: Theme = "light";

  const onSubmit: SubmitHandler<DownloadFields> = async (payload) => {
    try {
      if (!currentFile) {
        throw new Error("No file selected");
      }

      const resp = await getDownloadLink({
        ...payload,
        file_name: currentFile.file,
      });

      if (!formData) {
        setFormData({
          full_name: payload.full_name,
          email: payload.email,
          org: payload.org,
        });
      }

      if (resp?.url) {
        window.open(resp.url as string, "_blank");
      }

      form.reset({ justification: "" });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    styles,
    className,
    theme,
    form,
    onSubmit,
    currentFile,
  };
};
