import { type ForwardedRef } from "react";
import type { TextAreaVariantProps } from "./textarea.variants";
import { textareaVariant } from "./textarea.variants";

export type UseTextAreaProps = TextAreaVariantProps & {
  ref: ForwardedRef<HTMLTextAreaElement>;
  className?: string;
  label?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const useTextArea = (props: UseTextAreaProps) => {
  const {
    ref,
    className = "",
    theme = "dark",
    id,
    name,
    label,
    ...componentProps
  } = props;

  const fieldId = id || name;
  const styles = textareaVariant({ theme });

  return {
    className,
    styles,
    fieldId,
    label,
    componentProps: {
      ...componentProps,
      ref,
      name,
    },
  };
};
