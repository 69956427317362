import { tv, type VariantProps } from "../../../util/tv";

export const showcaseCardVariant = tv({
  slots: {
    base: [
      "group",
      "overflow-hidden",
      "rounded-2xl",
      "w-36 h-36",
      "shrink-0",
      "transition-all",
    ],
    wrapper: [
      "relative",
      "w-full h-full",
      "flex justify-center items-center",
      "overflow-hidden",
    ],
    innerWrapper: [
      "flex flex-col flex-wrap justify-items-start justify-between",
      "w-[calc(100%_-_2px)] h-[calc(100%_-_2px)]",
      "relative z-10",
      "rounded-2xl p-3",
      "bg-showcase-card-gradient from-black from-[12%] to-blue-300 to-[204%]",
    ],
    outerBlock: [
      "absolute -inset-[1px]",
      "rounded-2xl",
      "bg-gradient-to-tl from-blue-300 via-blue-400 from-[45%] to-black to-[110%]",
    ],
    header: [],
    icon: ["w-6 h-6"],
    title: ["select-none"],
    description: [],
  },
  variants: {
    theme: {
      dark: {},
      light: {},
    },
    state: {
      active: {
        outerBlock: ["animate-spin-slow"],
      },
      next: {},
      prev: {},
      visible: {
        base: ["blur-[3px]", "opacity-40"],
      },
      notVisible: {
        base: ["hidden"],
      },
    },
  },
  compoundVariants: [
    {
      state: ["next", "prev"],
      class: {
        base: ["blur-[1.5px]"],
      },
    },
    {
      state: ["next", "prev", "visible"],
      className: {
        base: ["backdrop-blur"],
      },
    },
  ],
  defaultVariants: {
    theme: "dark",
    // state: "notVisible",
  },
});

export type ShowcaseCardVariantProps = VariantProps<typeof showcaseCardVariant>;

export type ShowcaseCardReturnVariantProps = ReturnType<
  typeof showcaseCardVariant
>;
