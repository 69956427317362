"use client";

import { Video } from "../../common/video";
import { Vector } from "../../ui/vector";
import { ArtDirection } from "../../util/art-direction";
import {
  useHeroHighlight,
  type UseHeroHighlightProps,
} from "./use-hero-highlight";

export type HeroHighlightProps = UseHeroHighlightProps;

function HeroHighlight(props: HeroHighlightProps) {
  const { styles, mediaType, icon, imageHighlight } = useHeroHighlight(props);

  return (
    <div className={styles.base()}>
      {mediaType === "image" && (
        <>
          {!!icon && <Vector className={styles.icon()} name={icon} />}
          <ArtDirection
            containerClassName={styles.figure()}
            desktop={imageHighlight}
            priority
          />
        </>
      )}
      {mediaType === "video" && imageHighlight ? (
        <Video
          mime={imageHighlight.mime}
          size="full"
          src={imageHighlight.url}
        />
      ) : null}
    </div>
  );
}

export default HeroHighlight;
