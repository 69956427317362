import CodeGroupHeading from "./code-group-heading";
import CodeGroupPanels from "./code-group-panels";

function CodeGroup({
  children,
  title,

  ...props
}: React.ComponentPropsWithoutRef<typeof CodeGroupPanels> & { title: string }) {
  return (
    <div className="overflow-hidden rounded-xl bg-gray-900 shadow-md ring-1 ring-white/10">
      <div className="not-prose text-display-14">
        <CodeGroupHeading title={title} />
        <CodeGroupPanels {...props}>{children}</CodeGroupPanels>
      </div>
    </div>
  );
}

export default CodeGroup;
