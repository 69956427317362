import { Text } from "../../ui/text";
import { Avatar } from "../avatar";
import { useAuthor, type UseAuthorProps } from "./use-author";

export type AuthorProps = UseAuthorProps;

function Author(props: AuthorProps) {
  const {
    className,
    styles,
    theme,
    sizeAvatar,
    sizeName,
    data,
    date,
    readTime,
    showBio,
  } = useAuthor(props);
  const { name, picture, bio } = data;

  return (
    <div className={styles.base({ className })}>
      <div className={styles.details()}>
        <Avatar image={picture} size={sizeAvatar} />
        <div className={styles.author()}>
          <Text
            className={styles.name()}
            size={sizeName}
            tag="span"
            theme={theme}
            weight="semibold"
          >
            {name}
          </Text>
          {!!date && (
            <div className={styles.date()}>
              <Text size="tinyBody" tag="span" weight="medium">
                {date}
              </Text>
              {!!readTime && (
                <>
                  <Text
                    aria-hidden="true"
                    size="tinyBody"
                    tag="span"
                    weight="medium"
                  >
                    ·
                  </Text>
                  <Text size="tinyBody" tag="span" weight="medium">
                    {readTime}
                  </Text>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {showBio ? (
        <div className={styles.bio()}>
          <Text size="description" weight="medium">
            {bio}
          </Text>
        </div>
      ) : null}
    </div>
  );
}

export default Author;
