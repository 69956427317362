import type { ContactFields } from "../../types/forms";

const PORTAL_ID = "44307170";
const FORM_ID = "1f5ca9fc-ede3-4867-9307-cfc606be396e"; // old form: c94bd49b-9f82-4adb-a4ca-754a831ea6ab
const ENDPOINT_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`;

function getUtk() {
  return document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith("hubspotutk"))
    ?.split("=")[1];
}

export async function submitHubspotContactSalesForm(formData: ContactFields) {
  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: formData.email,
      },
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: formData.firstName,
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: formData.lastName,
      },
      {
        objectTypeId: "0-1",
        name: "phone",
        value: formData.phone,
      },
      {
        objectTypeId: "0-1",
        name: "primary_area_of_interest",
        value: formData.primaryAreaOfInterest,
      },
      {
        objectTypeId: "0-1",
        name: "jobtitle",
        value: formData.title,
      },
      {
        objectTypeId: "0-2",
        name: "name",
        value: formData.companyName,
      },
      {
        objectTypeId: "0-2",
        name: "companysize",
        value: formData.companySize,
      },
      {
        objectTypeId: "0-1",
        name: "message",
        value: formData.useCase,
      },
    ],
    context: {
      hutk: getUtk(),
    },
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(ENDPOINT_URL, options);

  if (response.ok) {
    const result = await response.json();

    if (result.errors?.length) {
      throw new Error(result.errors[0].message as string);
    }
  } else {
    throw new Error(
      `Failed with status code ${response.status}: ${response.statusText}`
    );
  }
}
