import { useEffect, useMemo, useState } from "react";
import { type SwiperClass } from "swiper/react";
import { useWindowSize } from "react-use";
import { Theme } from "../../types";
import { ShowcaseModule } from "../../types/showcase";
import { useShowcaseState } from "./use-showcase-state";
import { showcaseCarouselVariant } from "./showcase-carousel.variants";

export type UseShowcaseCarouselProps = {
  theme?: Theme;
  modules: ShowcaseModule[];
};

const MAX_WIDTH_MOBILE = 1024;

const navMobileVariant = {
  hidden: {
    y: 0,
    opacity: 1,
  },
  hover: {
    y: 0,
    opacity: 1,
  },
};

const navDesktopVariant = {
  hidden: {
    y: 10,
    opacity: 0,
  },
  hover: {
    y: 0,
    opacity: 1,
  },
};

export const useShowcaseCarousel = (props: UseShowcaseCarouselProps) => {
  const { theme = "dark", modules } = props;

  const [navVariants, setNavVariants] = useState(navDesktopVariant);

  const { width } = useWindowSize();
  const { setActiveModule } = useShowcaseState();

  const styles = showcaseCarouselVariant();

  const swiperId = `showcase-swiper`;
  const isMobile = useMemo(() => width <= MAX_WIDTH_MOBILE, [width]);

  const onSlideChange = (swiper: SwiperClass) => {
    const currentSlideIndex = swiper.activeIndex;
    const currentSlideElement = swiper.slides[currentSlideIndex];
    const moduleId = currentSlideElement?.dataset.moduleId;

    setActiveModule(moduleId!);
  };

  useEffect(() => {
    setNavVariants(isMobile ? navMobileVariant : navDesktopVariant);
  }, [isMobile]);

  return {
    styles,
    theme,
    swiperId,
    setActiveModule,
    modules,
    navVariants,
    onSlideChange,
  };
};
