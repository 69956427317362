"use client";

import { motion } from "motion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import { ShowcaseCard } from "./showcase-card";
import { SwiperNav } from "../util/swiper-nav";
import {
  useShowcaseCarousel,
  type UseShowcaseCarouselProps,
} from "./use-showcase-carousel";

type ShowcaseCarouselProps = UseShowcaseCarouselProps;

const ShowcaseCarousel = (props: ShowcaseCarouselProps) => {
  const { styles, swiperId, theme, modules, navVariants, onSlideChange } =
    useShowcaseCarousel(props);

  return (
    <motion.div
      className={styles.base()}
      initial="hidden"
      whileHover="hover"
      animate="hidden"
    >
      <Swiper
        className={styles.carousel()}
        modules={[EffectCoverflow, Navigation]}
        initialSlide={0}
        loop={true}
        speed={800}
        threshold={20}
        resistanceRatio={0.85}
        centeredSlides={true}
        slidesPerView="auto"
        onSlideChange={onSlideChange}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          stretch: 10,
          depth: 90,
          modifier: 1,
          slideShadows: false,
        }}
        breakpoints={{
          0: {
            allowTouchMove: true,
          },
          1024: {
            allowTouchMove: false,
          },
        }}
        navigation={{
          prevEl: `.prev-carousel-${swiperId}`,
          nextEl: `.next-carousel-${swiperId}`,
        }}
      >
        {modules.map((item, i) => {
          return (
            <SwiperSlide
              key={`${item.id}-${i}`}
              className={styles.slide()}
              data-module-id={item.id}
            >
              <ShowcaseCard theme={theme} data={item} />
            </SwiperSlide>
          );
        })}
        <motion.div
          className={styles.nav()}
          variants={navVariants}
          transition={{
            duration: 0.2,
            type: "tween",
            ease: "easeInOut",
          }}
        >
          <SwiperNav
            id={swiperId}
            theme={theme}
            buttonSize="sm"
            buttonColor="blackWithBorder"
            className={styles.nav()}
            prevClassName={styles.button({
              className: "-left-[calc(100%_+_24px)]",
            })}
            nextClassName={styles.button({
              className: "-right-[calc(100%_+_24px)]",
            })}
          />
        </motion.div>
      </Swiper>
    </motion.div>
  );
};

export { ShowcaseCarousel };
