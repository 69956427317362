import type { DownloadFields } from "../../types/forms";

const DATASET_API = "https://api.moises.ai";

export const getDownloadLink = async (payload: DownloadFields) => {
  const resp = await fetch(`${DATASET_API}/v3/download-research-assets`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!resp.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await resp.json();

  return data;
};
