import { useState } from "react";
import { workflowTabVariant } from "./workflow-tab.variants";
import { type LinksButton } from "../../../types";
import { type Theme } from "../../../types/strapi/common";

export type UseWorkflowTabModalProps = {
  sectionName: string;
  theme: Theme;
  button: LinksButton | null;
  jobId: string;
};

export const useWorkflowTabModal = (props: UseWorkflowTabModalProps) => {
  const { theme, sectionName, button, jobId } = props;
  const styles = workflowTabVariant({ theme });

  const [modalActive, setModalActive] = useState(false);

  const handleShowModal = () => {
    setModalActive(true);
  };

  const handleCloseModal = () => {
    setModalActive(false);
  };

  return {
    theme,
    styles,
    sectionName,
    button,
    jobId,
    modalActive,
    handleShowModal,
    handleCloseModal,
  };
};
