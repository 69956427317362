import { tv, type VariantProps } from "../../../util/tv";

export const workflowTabVariant = tv({
  slots: {
    base: ["container-fluid"],
    tabDescription: ["mb-4", "text-white"],
    tagWrapper: ["inline-flex flex-wrap gap-2"],
    tagTitle: ["uppercase", "text-gray-70", "mb-2 mt-6"],
    tag: [
      "px-[6px] py-[2px]",
      "rounded-[6px]",
      "bg-gray-600",
      "text-gray-70",
      "tracking-wide",
      "capitalize",
    ],
    buttonsWrapper: ["inline-flex items-center justify-start gap-8"],
    cta: ["my-6"],
    frame: ["rounded-2xl"],
    tabContentWrapper: ["grid grid-cols-12 gap-6 items-start justify-start"],
    col1: ["col-span-12 lg:col-span-5 flex flex-col"],
    col2: [
      "lg:mt-0",
      "col-span-12 sm:col-span-6 lg:col-span-3",
      "flex flex-col justify-start gap-4",
    ],
    col3: [
      "lg:mt-0",
      "col-span-12 sm:col-span-6 lg:col-span-3",
      "flex flex-col justify-start gap-4",
    ],
    emptyCol: ["col-span-12 lg:col-span-1"],
    colTitle: ["uppercase", "text-white"],
  },
});

export type WorkflowTabVariantProps = VariantProps<typeof workflowTabVariant>;

export type WorkflowTabReturnVariantProps = ReturnType<
  typeof workflowTabVariant
>;
