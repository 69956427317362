import { useCallback, useEffect, useMemo } from "react";
import { type WaveSurferInstances } from "./showcase-context";
import { useShowcaseState } from "./use-showcase-state";
import { useWavesurferSpectrum } from "./use-wavesurfer-spectrum";
import WaveSurfer from "wavesurfer.js";

const FREQUENCY_COUNT = 5;
const SPECTRUM_HEIGHT = 12;

export const useWavesurferState = (wavesurfer: WaveSurferInstances) => {
  const {
    currentModule,
    wavesurferState: { currentProgress, isReady, isPlaying },
    setWavesurferStatus,
    setWavesurferFrequency,
  } = useShowcaseState();

  useWavesurferSpectrum();

  const handleReady = useCallback(
    (track: string, ws: WaveSurfer) => {
      return () => {
        if (track === currentModule.track) {
          setWavesurferStatus("isReady", true);
          ws.setVolume(1);
          ws.setMuted(false);
        }
      };
    },
    [currentModule.track]
  );

  const handlePlay = useCallback(
    (track: string) => {
      return () => {
        if (track === currentModule.track) {
          setWavesurferStatus("isPlaying", true);
        }
      };
    },
    [currentModule.track]
  );

  const handlePause = useCallback(
    (track: string) => {
      return () => {
        if (track === currentModule.track) {
          setWavesurferStatus("isPlaying", false);
        }
      };
    },
    [currentModule.track]
  );

  const handleSeek = useCallback(
    (progress: number) => {
      if (currentProgress === progress) return;
      setWavesurferStatus("currentProgress", progress);
    },
    [currentModule.track]
  );

  const handleFinish = useCallback(
    (track: string) => {
      return () => {
        if (track === currentModule.track) {
          setWavesurferStatus("isPlaying", false);
        }
      };
    },
    [currentModule.track]
  );

  const handleDestroy = useCallback(
    (track: string) => {
      return () => {
        if (track === currentModule.track) {
          setWavesurferStatus("isReady", false);
          setWavesurferStatus("isPlaying", false);
        }
      };
    },
    [currentModule.track]
  );

  useEffect(() => {
    if (!wavesurfer || Object.keys(wavesurfer).length === 0) return;

    const events: (() => void)[] = [];

    Object.entries(wavesurfer).forEach(([track, ws]) => {
      ws.on("ready", handleReady(track, ws));
      ws.on("play", handlePlay(track));
      ws.on("pause", handlePause(track));
      ws.on("seeking", handleSeek);
      ws.on("finish", handleFinish(track));
      ws.on("destroy", handleDestroy(track));

      events.push(() => {
        ws.un("ready", handleReady(track, ws));
        ws.un("play", handlePlay(track));
        ws.un("pause", handlePause(track));
        ws.un("destroy", handleDestroy(track));
        ws.un("finish", handleFinish(track));
        ws.un("seeking", handleSeek);
      });
    });

    return () => {
      events.forEach((fn) => fn());
    };
  }, [wavesurfer]);

  useEffect(() => {
    const handlePlayerPlay = (event: CustomEvent) => {
      if (event.detail.wavesurfer !== wavesurfer) {
        Object.values(wavesurfer).forEach((ws) => {
          ws.pause();
        });
      }
    };

    window?.addEventListener("mai-player::play", handlePlayerPlay);

    return () => {
      window?.removeEventListener("mai-player::play", handlePlayerPlay);
    };
  }, [wavesurfer]);

  useEffect(() => {
    Object.entries(wavesurfer).forEach(([track, ws]) => {
      if (track !== currentModule.track) {
        ws.setTime(currentProgress);
      }
    });
  }, [wavesurfer, currentProgress]);

  useEffect(() => {
    const currentTrack = currentModule.track;

    Object.entries(wavesurfer).forEach(([track, ws]) => {
      if (track === currentTrack) {
        ws.setVolume(1);
        ws.setMuted(false);
      } else {
        ws.setVolume(0);
        ws.setMuted(true);
      }
    });
  }, [wavesurfer, currentModule.track]);

  const playPauseAll = useCallback(() => {
    if (!isReady) return;

    const newIsPlaying = !isPlaying;

    setWavesurferStatus("isPlaying", newIsPlaying);

    window.dispatchEvent(
      new CustomEvent("mai-player::play", { detail: { wavesurfer } })
    );

    Object.values(wavesurfer).forEach((ws) =>
      newIsPlaying ? ws.play() : ws.pause()
    );
  }, [wavesurfer, isReady, isPlaying]);

  useEffect(() => {
    const track = currentModule.track;

    const intervalId = setInterval(() => {
      if (!isPlaying) return;

      const maxHeight = SPECTRUM_HEIGHT;
      const newFrequencies = Array(FREQUENCY_COUNT)
        .fill(0)
        .map((_) =>
          Math.min(((Math.random() * 255) / 255) * maxHeight, maxHeight)
        );

      setWavesurferFrequency(track, newFrequencies);
    }, 100);

    return () => {
      setWavesurferFrequency(track, Array(FREQUENCY_COUNT).fill(2));
      clearInterval(intervalId);
    };
  }, [isPlaying, currentModule.track]);

  return useMemo(
    () => ({
      currentTrack: currentModule.track,
      isReady,
      isPlaying,
      playPauseAll,
    }),
    [currentModule.track, isReady, isPlaying]
  );
};
