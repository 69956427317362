import { tv, type VariantProps } from "../../../../../util/tv";

const compassVariant = tv({
  slots: {
    base: ["transition-opacity duration-500 ease-in-out"],
    grid: ["grid grid-cols-4 gap-1"],
  },
});

export type CompassVariantProps = VariantProps<typeof compassVariant>;

export { compassVariant };
