import { useMemo, useRef, type VideoHTMLAttributes } from "react";
import { video, type VideoVariantProps } from "./video.variants";

export type UseVideoProps = VideoVariantProps & {
  className?: string;
  containerClassName?: string;
  mime: string;
} & VideoHTMLAttributes<HTMLVideoElement>;

export const useVideo = (props: UseVideoProps) => {
  const {
    className = "",
    containerClassName = "",
    size = "video",
    src,
    mime,
    preload = "metadata",
    playsInline = false,
    autoPlay = false,
    loop = true,
    muted = true,
    ...componentProps
  } = props;

  const ref = useRef<HTMLVideoElement | null>(null);

  const styles = video({ size });

  const handlers = useMemo(() => {
    let videoLoaded: Promise<void> | undefined;

    return {
      start() {
        if (!src || !ref.current) return;
        videoLoaded = ref.current.play();
      },
      pause() {
        if (!src || !ref.current || !videoLoaded) return;

        videoLoaded
          .then(() => {
            if (ref.current) {
              ref.current.pause();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
    };
  }, [src]);

  return {
    styles,
    className,
    containerClassName,
    ref,
    handlers,
    src,
    mime,
    componentProps: {
      preload,
      playsInline,
      autoPlay,
      loop,
      muted,
      ...componentProps,
    },
  };
};
