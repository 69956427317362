"use client";

import NextImage from "next/image";
import YouTube from "react-youtube";
import { Vector } from "../../ui/vector";
import { Modal } from "../../util/modal";
import { useVideoCard, type UseVideoCardProps } from "./use-video-card";

export type VideoCardProps = UseVideoCardProps;

function VideoCard(props: VideoCardProps) {
  const {
    styles,
    className,
    videoId,
    poster,
    priority,
    data,
    modalState,
    setModalState,
    handleClick,
  } = useVideoCard(props);

  const { title, cover } = data;

  return (
    <>
      <div
        aria-label={title || cover?.alternativeText || "video"}
        aria-pressed={modalState}
        className={styles.base({ className })}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setModalState(true);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <span className={styles.play()}>
          <Vector className={styles.icon()} name="player-play-filled" />
        </span>
        <NextImage
          alt={cover?.alternativeText || title || "poster"}
          className={styles.image()}
          fill
          priority={priority}
          quality={95}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 40vw"
          src={cover?.url || poster}
        />
      </div>
      <Modal
        active={modalState}
        handleClose={() => {
          setModalState(false);
        }}
      >
        <div className="p-4">
          <YouTube
            className={styles.video()}
            iframeClassName="rounded-xl"
            onEnd={() => {
              setModalState(false);
            }}
            opts={{
              playerVars: {
                autoplay: 1,
                color: "white",
                iv_load_policy: 3,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
              },
            }}
            videoId={videoId}
          />
        </div>
      </Modal>
    </>
  );
}

export default VideoCard;
