import { Text } from "../../../../ui/text";
import { useBeat, type UseBeatProps } from "./use-beat";

export type BeatProps = UseBeatProps;

const CHORDS_MAP: Record<string, string> = {
  N: `𝄽`,
};

const Beat = (props: BeatProps) => {
  const { styles, note, type, bass, isActiveBeat } = useBeat(props);

  return (
    <div className={styles.base()} data-active-beat={isActiveBeat}>
      {note && (
        <Text className={styles.chord()} tag="span">
          {CHORDS_MAP[note] || note}
          {!!type && <span className={styles.type()}>{type}</span>}
          {!!bass && `/${bass}`}
        </Text>
      )}
    </div>
  );
};

export { Beat };
