import { Beat } from "../beat";
import { useCompass, UseCompassProps } from "./use-compass";

export type CompassProps = UseCompassProps;

const Compass = (props: CompassProps) => {
  const { styles, bar, barIndex, barId, actions } = useCompass(props);

  const isActiveCompass = actions.isPlayedCompass(barIndex);

  return (
    <div className={styles.base()} data-active-compass={isActiveCompass}>
      <div className={styles.grid()}>
        {bar.beats.map(({ chord, isOriginal }, beatIndex) => (
          <Beat
            key={`beat-${barId}-${beatIndex}`}
            chord={chord}
            isOriginal={isOriginal}
            isActiveCompass={isActiveCompass}
            isActiveBeat={actions.isActiveBeat(barIndex, beatIndex)}
          />
        ))}
      </div>
    </div>
  );
};

export { Compass };
