import { Button, type ButtonProps } from "../../ui/button";
import { motion } from "framer-motion";

interface CarouselButtonProps {
  className: string;
  startIcon: ButtonProps["startIcon"];
}

function CarouselButton({ className, startIcon }: CarouselButtonProps) {
  return (
    <motion.div
      transition={{ delay: 0.3, ease: "easeOut", duration: 0.5 }}
      whileInView={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      <Button
        appearance="solid"
        className={className}
        color={"blackWithBorder"}
        rounded
        size="md"
        startIcon={startIcon}
        type="button"
      />
    </motion.div>
  );
}

export default CarouselButton;
